import React, {Component} from "react";
import {EditText} from 'react-edit-text';
import classNames from "classnames";
import {ReactComponent as ReactCloseIcon} from "../recources/icons/close-icon.svg"
import {ReactComponent as ReactDragIcon} from "../recources/icons/drag-icon.svg"
import {ReactComponent as ReactCrossIcon} from "../recources/icons/cross-icon.svg"
import {ReactComponent as ReactPlusIcon} from "../recources/icons/plus-icon.svg"
import {ReactComponent as ReactImportIcon} from "../recources/icons/import.svg"
import {ReactComponent as ReactImportHoverIcon} from "../recources/icons/import-hover.svg"
import {ReactComponent as ReactNewEmptyIcon} from "../recources/icons/new-empty.svg"
import {ReactComponent as ReactNewEmptyHoverIcon} from "../recources/icons/new-empty-hover.svg"



import HNPF_logo_en_tekst from '../recources/images/HNPF-tekst-en-logo.png'

import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import Sortly, {ContextProvider, useDrag, useDrop} from 'react-sortly';
import Header from "../Header";
import axios from "axios";
import moment from "moment";

import {ReactComponent as PrintIcon} from "../recources/icons/Pagina-afdrukken-Icoon.svg"
import { Print, NoPrint } from 'react-easy-print';

class Home extends Component {

    getCurrentAgendaFromDatabase() {
        axios.post(
            "/agenda/get_agenda",
            {
                id: this.props.match.params.id,
                ids: [1, 2],
                rollen: [1, 2],
            },
            {withCredentials: true}
        ).then((response) => {
            this.setState({
                currentAgenda: response.data,
            });
        });
    }

    getAgendaDataFromDatabase() {
        const id = this.props.match.params.id;

        function groupBy(key) {
            return function group(array) {
                return array.reduce((acc, obj) => {
                    const property = obj[key];
                    acc[property] = acc[property] || [];
                    acc[property].push(obj);
                    return acc;
                }, {});
            };
        }

        axios.post(
            "/agenda/get_agenda_inhoud",
            {
                id: id,
            },
            {withCredentials: true}
        ).then((response) => {
            const groupByyear = groupBy("onderdeel_id");
            let groupedArray = groupByyear(typeof response.data === 'string' ? [] : response.data);
            let newArray = [];
            let parentIndex = 1;

            for (const onderdeel in groupedArray) {
                let toggle = true;
                let childIndex = 1;

                for (const onderwerp in groupedArray[onderdeel]) {

                    groupedArray[onderdeel][onderwerp].childId = childIndex;
                    groupedArray[onderdeel][onderwerp].parentId = parentIndex;
                    groupedArray[onderdeel][onderwerp].depth = 0;
                    groupedArray[onderdeel][onderwerp].name = groupedArray[onderdeel][onderwerp].naam;

                    if (toggle) {
                        if (groupedArray[onderdeel][onderwerp].id !== null) {
                            newArray.push({
                                id: onderdeel,
                                childId: 0,
                                parentId: parentIndex,
                                depth: 0,
                                tijdsduur: groupedArray[onderdeel][onderwerp].onderdeel_tijdsduur,
                                index_onderdeel: groupedArray[onderdeel][onderwerp].index_onderdeel,
                                name: groupedArray[onderdeel][onderwerp].onderdeel_naam,
                                childData: groupedArray[onderdeel],
                            })
                        } else {
                            newArray.push({
                                id: onderdeel,
                                childId: 0,
                                parentId: parentIndex,
                                depth: 0,
                                tijdsduur: groupedArray[onderdeel][onderwerp].onderdeel_tijdsduur,
                                onderdeel_id: groupedArray[onderdeel][onderwerp].onderdeel_id,
                                index_onderdeel: groupedArray[onderdeel][onderwerp].index_onderdeel,
                                name: groupedArray[onderdeel][onderwerp].onderdeel_naam,
                                childData: [],
                            })
                        }
                        toggle = false;
                    }
                    childIndex++;
                }
                parentIndex++;
            }
            this.setState({
                agenda: newArray,
                hideImportFromYearPlanningToggle: false
            });

        });
    }

    constructor(props) {
        super(props);
        this.state = {
            modalTitle: "Nieuwe jaarplanning aanmaken",
            agenda: [],
            hideImportFromYearPlanningToggle: true,
            errorMessageToggle: false,
            errorMessage: "",
            startWithEmptyAgenda: false,
            readonly: false,
            loggedInUser: JSON.parse(localStorage.getItem("914ebf336ee44d9deba3f19de35417ace22dc318b8a1024bcd9fe9b4f083a9ae")),
        }

        this.getCurrentAgendaFromDatabase();
        this.getAgendaDataFromDatabase();

        this.importFromYearPlanning = this.importFromYearPlanning.bind(this);
        this.emptyYearPlanning = this.emptyYearPlanning.bind(this);
        this.handleClickDelete = this.handleClickDelete.bind(this);
        this.agendaHeader = this.agendaHeader.bind(this);
        this.addTopic = this.addTopic.bind(this);
        this.handleClickInsert = this.handleClickInsert.bind(this);
    }

    
  getLogo () {
    axios.post(
      "/user/get_groot_logo",
      {
        instantie_id: this.state.loggedInUser.instantie_id,
      },
      { withCredentials: true }
    )
    .then(res => {
      this.setState({
        groot_logo: res.data
      });
    }).catch((error) => {
      // Log it. A missing logo is not serious
      console.log(error);
    });
  }

    componentDidMount() {
        this.getLogo();
        if (this.props.location && this.props.location.search && this.props.location.search === "?readonly=true") {
            this.setState({ readonly: true});
        }
    }

    setError(errorMessage) {
        window.clearTimeout();
        this.setState({
            errorMessageToggle: true,
            errorMessage: errorMessage
        });
        const errorMessageToggleFalse = () => {
            this.setState({errorMessageToggle: false});
        }
        setTimeout(errorMessageToggleFalse, 5000);
    }

    closeModals() {
        let removeConfirmModal = document.getElementById("remove-confirm-modal");
        removeConfirmModal.style.display = "none";
        removeConfirmModal.style.opacity = 0;
    }

    importFromYearPlanning() {
        axios.post(
                "/agenda/import_jaarplanning_to_agenda",
                {
                    agenda_id: this.props.match.params.id,
                },
                { withCredentials: true }
            )
            .then(() => {
                this.getAgendaDataFromDatabase();
            }).catch((error) => {
                if (error.response.data.message === "No corresponding jaarplanning") {
                    this.setError("Er is geen jaarplanning gevonden om in deze agenda te importeren. Zorg voor een jaarplanning met corresponderend orgaan en jaar")
                }
        });
    }

    emptyYearPlanning() {
        axios.post(
            "/agenda/import_format_agenda",
            {
                agenda_id: this.props.match.params.id,
            }, {withCredentials: true}
        ).then(() => {
            // this.getAgendaDataFromDatabase();
        })
        this.setState({
            hideImportFromYearPlanningToggle: true,
            startWithEmptyAgenda: true
        });
    }

    handleClickDelete() {
        const onderwerpId = this.state.onderwerpToRemoveId;
        const onderdeelId = this.state.onderdeelToRemoveId;
        let axiosUrl = "";
        let id = null;

        if (onderwerpId) {
            axiosUrl = "/agenda/remove_agenda_onderwerp";
            id = onderwerpId;
        } else {
            axiosUrl = "/agenda/remove_agenda_onderdeel";
            id = onderdeelId;
        }
        axios.post(
            axiosUrl,
            {
                id: id,
            },
            {withCredentials: true}
        ).then(() => {
            this.setState({
                onderwerpToRemoveId: null,
                onderdeelToRemoveId: null,
                textToRemove: ""
            })
            this.getAgendaDataFromDatabase();
            this.closeModals();
        });
    };

    agendaHeader() {

        const handleOnSaveLocation = (data) => {
            if (data.value.length > 45) {
                this.setError("Gebruik maximaal 45 karakters voor een locatie. Wijziging is niet opgeslagen!");
                return;
            } else if (!data.value.replace(/\s/g, '').length) {
                data.value = null;
            }

            const formattedData = {
                id: this.props.match.params.id,
                column: "locatie",
                value: data.value,
            }

            axios.post(
                    "/agenda/update_agenda",
                    {
                        agenda: formattedData,
                    },
                    { withCredentials: true }
                );
        }

        if (this.state.currentAgenda) {
            return (
                <div className="agenda-info">
                    <div className="row agenda-header">
                        <div className="offset-1 col-4">
                            <table>
                                <tbody>
                                <tr>
                                    <td className="agenda-header-description">Type</td>
                                    <td>{this.state.currentAgenda[0].type_agenda}</td>
                                </tr>
                                <tr>
                                    <td className="agenda-header-description">Orgaan</td>
                                    <td>{this.state.currentAgenda[0].orgaan}</td>
                                </tr>
                                <tr>
                                    <td className="agenda-header-description">Instantie</td>
                                    <td>{this.state.currentAgenda[0].instantie}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col-3">
                            <table>
                                <tbody>
                                <tr>
                                    <td className="agenda-header-description">Datum</td>
                                    <td>{moment(this.state.currentAgenda[0].datum).format('LL')}</td>
                                </tr>
                                <tr>
                                    <td className="agenda-header-description">Tijd</td>
                                    <td>
                                        {moment(this.state.currentAgenda[0].start_tijd, 'HH:mm:ss').format('HH:mm')
                                        + " - " +
                                        moment(this.state.currentAgenda[0].eind_tijd, 'HH:mm:ss').format('HH:mm')}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="agenda-header-description">Locatie</td>
                                    <td>
                                        { this.state.readonly ? <p>{this.state.currentAgenda[0].locatie}</p> :
                                            <EditText defaultValue={this.state.currentAgenda[0].locatie}
                                                    onSave={event => handleOnSaveLocation(event)}
                                                    placeholder="Verander locatie..."/>
                                        }
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col-2">
                            <img src={this.state.groot_logo} height="70px" alt="HNPF-logo"/>
                        </div>
                    </div>
                </div>
            )
        }
    }

    addTopic(childId, childDataLength, items1, onderdeel_id) {
        if (childId === 0) {
            return this.state.readonly ? <div></div> : <div onClick={() => this.handleClickInsert(childDataLength, items1, onderdeel_id)} className="addTopic">
                    <ReactPlusIcon/>Nieuw onderwerp toevoegen
                </div>;
        }
    }

    //add agenda onderwerp
    handleClickInsert(childDataLength, items1, onderdeel_id) {
        const agendaOnderwerpData = {
            index_onderwerp: childDataLength,
            naam: "",
        }

        if (items1.length !== 0) {
            agendaOnderwerpData.onderdeel_id = items1[0].onderdeel_id;
        } else {
            agendaOnderwerpData.onderdeel_id = onderdeel_id;
        }

        axios.post(
            "/agenda/add_agenda_onderwerp",
            {
                agenda_onderwerp: agendaOnderwerpData,
            },
            {withCredentials: true}
        ).then(() => {
            this.getAgendaDataFromDatabase();
        });
    };


    render() {

        const ItemRenderer1 = (props) => {
            const {data: {id, name, index_onderdeel}} = props;
            const [, drag, preview] = useDrag();
            const [, drop] = useDrop();

            const openRemoveModal = (props) => {
                this.setState({
                    onderwerpToRemoveId: id,
                    textToRemove: `${props.data.index_onderdeel + 1}.${props.data.index_onderwerp + 1}`
                });
                let modal = document.getElementById("remove-confirm-modal");
                modal.style.display = "block";
                modal.style.opacity = 1;
            };

            const handleOnSaveOnderwerp = (data) => {

                if (data.value.length > 80) {
                    this.setError("Gebruik maximaal 80 karakters voor een onderwerp. Wijziging is niet opgeslagen!")
                    return;
                }

                if (!data.value.replace(/\s/g, '').length) {
                    data.value = null;
                }

                const onderwerpToUpdate = {
                    id: id,
                    column: "naam",
                    value: data.value,
                }
                axios.post(
                    "/agenda/update_agenda_onderwerp",
                    {
                        agenda_onderwerp: onderwerpToUpdate,
                    },
                    {withCredentials: true}
                ).then(() => {
                    this.getAgendaDataFromDatabase();
                });
            }

            const handleOnSaveDuration = (data) => {

                if (isNaN(data.value)){
                    this.setError("Alleen nummers toegestaan. Wijziging is niet opgeslagen!")
                    return;
                } else if (data.value.length > 3) {
                    this.setError("Maximale duur is 999 minuten. Wijziging is niet opgeslagen!")
                    return;
                } else if (data.value < 0) {
                    this.setError("Een getal onder de nul is niet toegestaan. Wijziging is niet opgeslagen!")
                    return;
                }

                const duration = moment.utc(moment.duration(data.value, 'minutes').asMilliseconds()).format("HH:mm:ss");

                const onderwerpToUpdate = {
                    id: id,
                    column: "tijdsduur",
                    value: duration,
                }
                axios.post(
                    "/agenda/update_agenda_onderwerp",
                    {
                        agenda_onderwerp: onderwerpToUpdate,
                    },
                    {withCredentials: true}
                ).then(() => {
                    this.getAgendaDataFromDatabase();
                });
            }

            const durationField = () => {
                let duration = null;
                if (props.data.tijdsduur && props.data.tijdsduur != "00:00:00") {
                    duration = moment.duration(props.data.tijdsduur).asMinutes();
                }
                return (
                    <div className="duration">
                        { this.state.readonly ? <p>{duration ? duration + ' min' : duration}</p> :
                            <>
                                <EditText defaultValue={duration ? duration.toString() : null}
                                    onSave={event => handleOnSaveDuration(event)}
                                    placeholder="... min"
                                />
                                <span className={duration ? "" : "d-none"}> min</span>
                            </>
                        }
                    </div>
                )
            }

            const handleOnSaveToelichting = (data) => {

                if (data.value.length > 30) {
                    this.setError("Gebruik maximaal 30 karakters voor een opmerking. Wijziging is niet opgeslagen!")
                    return;
                } else if (!data.value.replace(/\s/g, '').length) {
                    data.value = null;
                }

                const onderwerpToUpdate = {
                    id: id,
                    column: "toelichting",
                    value: data.value,
                }
                axios.post(
                    "/agenda/update_agenda_onderwerp",
                    {
                        agenda_onderwerp: onderwerpToUpdate,
                    },
                    {withCredentials: true}
                ).then(() => {
                    this.getAgendaDataFromDatabase();
                });
            }

            return (
                <div ref={(ref) => drop(preview(ref))} className="drag-row child onderwerp" style={{marginLeft: 50}}>
                        <>
                            <span className="drag" ref={this.state.readonly ? null : drag}><ReactDragIcon/></span>
                                
                                <span className="indexNumber">{props.data.parentId}.{props.index + 1}</span>
                            <div>
                                { this.state.readonly ? <span>{name}</span> :
                                    <EditText defaultValue={name}
                                            onSave={event => handleOnSaveOnderwerp(event)}
                                            placeholder="Onderwerp wijzigen"
                                    />
                                }
                            </div>
                        </>
                    <span className="agenda-toelichting">
                        {
                            this.state.readonly ? <span>{props.data.toelichting}</span> :
                            <EditText defaultValue={props.data.toelichting}
                                    onSave={event => handleOnSaveToelichting(event)}
                                    placeholder="Voeg opmerking toe"
                            />
                        }
                    </span>
                        { !this.state.readonly &&
                            <span className="remove" onClick={() => openRemoveModal(props)}><ReactCrossIcon/></span>
                        }
                        <span className="mr-5 float-right">
                            {durationField()}
                        </span>
                </div>
            );
        };

        const ItemRenderer = (props, test) => {
            const {data: {id, name, childId, childData, onderdeel_id, tijdsduur}} = props;
            const [, drag, preview] = useDrag();
            const [, drop] = useDrop();

            let dragRowClasses = classNames({
                'drag-row': true,
                'child': childId
            });

            const [items1, setItems1] = React.useState(childData);

            const handleChange = (newItems) => {
                setItems1(newItems);

                //updating the index_onderwerp value in the database on the change of the user action
                newItems.map((item, index) => {
                    const updatedAgenda = {
                        id: item.id,
                        column: "index_onderwerp",
                        value: index,
                    }
                    axios.post(
                        "/agenda/update_agenda_onderwerp",
                        {
                            agenda_onderwerp: updatedAgenda,
                        },
                        {withCredentials: true}
                    );
                })
            };

            const openRemoveModal = (props) => {
                this.setState({
                    onderdeelToRemoveId: id,
                    textToRemove: props.data.index_onderdeel + 1
                });
                let modal = document.getElementById("remove-confirm-modal");
                modal.style.display = "block";
                modal.style.opacity = 1;
            };

            const handleOnSaveOnderdeel = (data) => {

                if (data.value.length > 45) {
                    this.setError("Gebruik maximaal 45 karakters voor een onderdeel. Wijziging is niet opgeslagen!")
                    return;
                } else if (!data.value.replace(/\s/g, '').length) {
                    data.value = null;
                }

                const onderdeelToUpdate = {
                    id: id,
                    column: "naam",
                    value: data.value,
                }
                axios.post(
                    "/agenda/update_agenda_onderdeel",
                    {
                        agenda_onderdeel: onderdeelToUpdate,
                    },
                    {withCredentials: true}
                ).then(() => {
                    this.getAgendaDataFromDatabase();
                });
            }

            const durationField = () => {
                let totalDuration = 0;

                childData.forEach(function (child) {
                    if (child.tijdsduur) {
                        totalDuration = totalDuration + moment.duration(child.tijdsduur);
                    }
                })

                const handleOnSaveDuration = (data) => {
                    const duration = moment.utc(moment.duration(data.value, 'minutes').asMilliseconds()).format("HH:mm:ss");

                    const updatedAgenda = {
                        id: id,
                        column: "tijdsduur",
                        value: duration,
                    }
                    axios.post(
                        "/agenda/update_agenda_onderdeel",
                        {
                            agenda_onderdeel: updatedAgenda,
                        },
                        {withCredentials: true}
                    ).then(() => {
                        this.getAgendaDataFromDatabase();
                    });
                }

                if (!totalDuration) {
                    let tijdsduurInMinuten = moment.duration(tijdsduur).asMinutes();
                    // tijdsduurInMinuten wordt op null gezet als 0 om de placeholder te tonen.
                    tijdsduurInMinuten = tijdsduurInMinuten === 0 ? null : String(tijdsduurInMinuten);

                    return (<div className="duration">
                                { this.state.readonly ? <p>{tijdsduurInMinuten ? tijdsduurInMinuten + ' min' : tijdsduurInMinuten}</p> :
                                    <>
                                        <EditText defaultValue={tijdsduurInMinuten}
                                                onSave={event => handleOnSaveDuration(event)}
                                                placeholder="... min"
                                        />
                                        <span className={tijdsduurInMinuten ? "" : "d-none"}> min</span>
                                    </>
                                }
                    </div>)
                }

                if (moment.duration(totalDuration).asMinutes()) {
                    return (moment.duration(totalDuration).asMinutes() + " min")
                } else {
                    return (<span className="_3qwMT">... min</span>)
                }
            }

            return (
                <div ref={(ref) => drop(preview(ref))}>
                    <div className={dragRowClasses}>
                        { !this.state.readonly &&
                            <span className="drag" ref={drag}><ReactDragIcon/> </span>
                        }
                        <span className="indexNumber">{props.index + 1}</span>
                        <div>
                        { this.state.readonly ? <span>{name}</span> :
                            <EditText defaultValue={name}
                                      onSave={event => handleOnSaveOnderdeel(event)}
                                      placeholder="Onderdeel wijzigen"
                            />
                        }
                        </div>
                        { !this.state.readonly &&
                            <span className="remove" onClick={() => openRemoveModal(props)}><ReactCrossIcon/></span>
                        }
                        <span className="mr-5 float-right">
                            {durationField()}
                        </span>
                        <Sortly maxDepth={0} items={items1} onChange={handleChange}>
                            {(props) => <ItemRenderer1
                                {...props}
                            />}
                        </Sortly>
                    </div>
                    {this.addTopic(childId, props.data.childData.length, items1, onderdeel_id)}
                </div>
            );
        };

        const AgendaData = () => {
            const ITEMS = this.state.agenda.sort(function (a, b) {
                return a.index_onderdeel - b.index_onderdeel;
            });
            const [items, setItems] = React.useState(ITEMS);

            const handleChange = (newItems) => {
                setItems(newItems);

                //updating the index_onderwerp value in the database on the change of the user action
                newItems.map((item, index) => {
                    const updatedAgenda = {
                        id: item.id,
                        column: "index_onderdeel",
                        value: index,
                    }
                    axios.post(
                        "/agenda/update_agenda_onderdeel",
                        {
                            agenda_onderdeel: updatedAgenda,
                        },
                        {withCredentials: true}
                    );
                })
            };

            const handleClickAdd = () => {
                const formattedAgendaData = {
                    agenda_id: this.props.match.params.id,
                    index_onderdeel: 999,
                    naam: "",
                }

                axios.post(
                    "/agenda/add_agenda_onderdeel",
                    {
                        agenda_onderdeel: formattedAgendaData,
                    },
                    {withCredentials: true}
                ).then(() => {
                    this.getAgendaDataFromDatabase();
                });
            };

            const addOnderdeelButton = () => {
                if (this.state.agenda.length !== 0 || (this.state.startWithEmptyAgenda && this.state.hideImportFromYearPlanningToggle)) {
                    return this.state.readonly ? <div></div> :
                        <div onClick={handleClickAdd} className="addTopic addPart"><ReactPlusIcon/>Nieuw onderdeel toevoegen
                        </div>;
                }
            }

            const importFromYearPlanningButton = () => {
                if (this.state.agenda.length === 0 && !this.state.hideImportFromYearPlanningToggle) {
                    return (
                        !this.state.readonly ?
                            <div className="import-options">
                                <button onClick={this.importFromYearPlanning} type="button" className='import-agenda'>
                                    <p>Importeer vanuit een jaarplanning</p> <ReactImportIcon/>
                                </button>
                                <p>Of</p> 
                                <button onClick={this.emptyYearPlanning} type="button" className='empty-agenda'>
                                    <p>Start met een lege vergader agenda </p> <ReactNewEmptyIcon/>
                                </button>
                            </div> : 
                            <div></div>
                    );
                }
            }

            return (
                <div>
                    <Sortly maxDepth={0} items={items} onChange={handleChange}>
                        {(props) => <ItemRenderer
                            {...props}
                        />}
                    </Sortly>
                    {addOnderdeelButton()}
                    {importFromYearPlanningButton()}
                </div>
            );
        };

        const headerText = () => {
            if (this.state.currentAgenda) {
                return (
                    "Agenda " +
                    this.state.currentAgenda[0].type_agenda + " " +
                    this.state.currentAgenda[0].orgaan + " " +
                    this.state.currentAgenda[0].instantie + " " +
                    moment(this.state.currentAgenda[0].datum).format('LL')
                );
            }
        }

        const onClickPrintBtn = () => {
            window.scrollTo(0, 0);
            window.print();
        }

        const printButton =
            <button className='roundButton' onClick={() => onClickPrintBtn()}>
                <PrintIcon className='roundIcon'/>
            </button> ;

        return (
            <Print>
                <NoPrint>
                    <Header titlePage={headerText()} backOption="1" testPrint={printButton} formatOption="0" />
                </NoPrint>

                <Print printOnly>
                    <div style={{color: '$primary', fontWeight: '900', fontSize: '25px', textAlign: 'center'}}>
                        {headerText()}
                    </div>
                </Print>

                <div className="year-plannings">
                    <Print>
                    {this.agendaHeader()}
                    </Print>

                    <Print>
                        <DndProvider backend={HTML5Backend}>
                            <ContextProvider>
                                <AgendaData/>
                            </ContextProvider>
                        </DndProvider>
                    </Print>

                    <div className={this.state.errorMessageToggle ? "error-message" : "error-message d-none"}>{this.state.errorMessage}</div>

                    <div id="remove-confirm-modal" className="modal">
                        <div className="modal-content">
                            <div className="modal-head">
                                Weet u zeker dat u dit wilt verwijderen?
                            </div>
                            <ReactCloseIcon onClick={this.closeModals} className="close-icon"/>
                            <form action="">
                                <div  className="user-for-deletion">U verwijdert {this.state.textToRemove}</div>
                                <div className="row">
                                    <div className="col-sm-6 m-auto d-flex">
                                        <input onClick={this.handleClickDelete} className="button" role="button" type="button" value="Ja"/>
                                    </div>
                                    <div className="col-sm-6 m-auto d-flex">
                                        <input onClick={this.closeModals} className="button gray" role="button"
                                               type="button" value="Nee"/>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </ Print>
        );
    }
}

export default Home;