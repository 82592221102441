import React, { Component } from "react";

import Header from "../Header";
import axios from "axios";
import sha256 from "js-sha256";
import { ReactComponent as ReactEditIcon } from "../recources/icons/edit-icon.svg"
import { ReactComponent as ReactDeleteIcon } from "../recources/icons/delete-icon.svg"
import { ReactComponent as ReactNewUsersIcon } from "../recources/icons/new-users-icon.svg"
import { ReactComponent as ReactCloseIcon } from "../recources/icons/close-icon.svg"
import { ReactComponent as ReactCheckIcon } from "../recources/icons/check-icon.svg"
import { ReactComponent as ReactWarningIcon } from "../recources/icons/warning.svg"

class Users extends Component {

    getAllUsers() {
        let loggedInUser = JSON.parse(localStorage.getItem("914ebf336ee44d9deba3f19de35417ace22dc318b8a1024bcd9fe9b4f083a9ae"));

        axios.post("/user/get_filtered_users", 
        {
            instantie_id: loggedInUser.instantie_id,
        },
        {
            withCredentials: true,
        }).then((response) => {
            this.setState({
                filteredUsers: response.data,
            });
        }).catch((error) => {
            console.log("task fetching error", error);
        });

        axios.post("/user/get_users", 
        {
            instantie_id: loggedInUser.instantie_id,
        },
        { 
            withCredentials: true,
        }).then((response) => {
            this.setState({
                users: response.data,
            });
        }).catch((error) => {
            console.log("task fetching error", error);
        });

        axios.post("/rollen/get_organen", 
        {
            instantie_id: loggedInUser.instantie_id,
        },
        {
            withCredentials: true,
        }).then((response) => {
            this.setState({
                yearPlanningOrganen: response.data,
            })
        }).catch((error) => {
            console.log("task fetching error", error);
        });
    }


    constructor(props) {
        super(props);
        this.state = {
            options: [],
            addFormId: '',
            addFormName: "",
            addFormLastName: '',
            addFormMail: '',
            addFormOrgaan: [],
            addFormOrgaanName: "",
            addFormOrgaanUsers: [],
            addFormIsAdmin: false,
            yearPlanningOrganen: [],
            addFormPassword: '',
            removeId: 0,
            removeIsAdmin: false,
            modalTitle: "",
            adminMax: 3,
            editMax: 4,
            viewersMax: 14,
            user: JSON.parse(localStorage.getItem("914ebf336ee44d9deba3f19de35417ace22dc318b8a1024bcd9fe9b4f083a9ae")),
            userListState: '',
            users: [],
            modalButton: '',
            filteredUsers: [],
            useRol: '',
            sortUser: false,
            sortRol: false,
            types: [{ 'naam': 'Admin', 'id': 1 }, { 'naam': 'Bewerker', 'id': 2 }, { 'naam': 'Bekijker', 'id': 3 }],
            errorMessageToggle: false,
            organen: [],
            rechten: [],
            rechtenOrgaan: [],
            ledenRechtenForOrgaan: []
        }


        this.getAllUsers();
        this.handleOnChangeAddUserForm = this.handleOnChangeAddUserForm.bind(this);
        this.handleSubmitNewUser = this.handleSubmitNewUser.bind(this);
        this.handleRemoveUserAccepted = this.handleRemoveUserAccepted.bind(this)
        this.calculateRechtenVoorUser = this.calculateRechtenVoorUser.bind(this);
        this.calculateUsersVoorOrgaan = this.calculateUsersVoorOrgaan.bind(this);
    
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.yearPlanningOrganen === []) {
            this.createRechten()
        }
    }
    createRechten() {
        let organen = this.state.yearPlanningOrganen
        let rechtenOrgaan = []
        organen.forEach(orgaan => {
            rechtenOrgaan.push({ 'orgaan': orgaan, 'bewerker': 0, 'bekijker': 0 })
        })
        this.setState({
            orgaanAndRechten: rechtenOrgaan
        })
    }

    resetAddUserFormFields() {
        this.setState({
            addFormName: "",
            addFormLastName: "",
            addFormMail: "",
            addFormOrgaan: "",
            addFormIsAdmin: false,
            addFormPassword: ""
        })
    }


    userTableRows() {
        let users = this.state.filteredUsers

        if (this.state.userListState == '') {
            return (
                <>
                    {users.map((user, index) => (

                        <tr className={index} key={index}>
                            <td>{user.naam} </td>
                            {user.is_admin ? <td><ReactCheckIcon /></td> : <td></td>}
                            <td>{user.rol}
                            </td>

                            <td className="edit-table-row" onClick={event => { this.setState({ modalTitle: "Gebruiker bewerken" }); this.setState({ modalButton: "Gebruiker opslaan" }); this.openEditModal(user.gebruiker_id, event) }}><ReactEditIcon /></td>
                            <td className="delete-table-row" onClick={event => this.openRemoveUserModal(user, event) + this.setState({ modalTitle: 'Weet u zeker dat u deze gebruiker wilt verwijderen?' })}><ReactDeleteIcon /></td>
                        </tr>
                    )


                    )}

                </>
            )

        }



    }

    findSelectedOrganen() {
        let rollen = []
        let rechten = []

        this.state.rechtenOrgaan.forEach(r => {
            if (r.bewerker || r.bekijker) {
                rollen.push(r.orgaan_id)
                rechten.push(r.bewerker ? 2 : 3)

            }

        })
        return { orgaan: rollen, rechten }
    }
    findSelectedUsers() {
        let leden = []
        let rechten = []

        this.state.ledenRechtenForOrgaan.forEach(lid => {
            if (lid.bewerker || lid.bekijker) {
                leden.push(lid.user_id)
                rechten.push(lid.bewerker ? 2 : 3)
            }
        })
        return { leden: leden, rechten }
    }
    handleSubmitNewUser(e) {
        e.preventDefault();
        let rollen = this.findSelectedOrganen()
        if (this.state.modalTitle === "Gebruiker bewerken") {
            axios.post(
                "/user/update_user",
                {
                    user: {
                        id: this.state.userId,
                        emailadres: this.state.addFormMail,
                        rollen,
                    },
                },
                { withCredentials: true }
            ).then(() => {
                this.getAllUsers();
                this.closeModals();
            }).catch((error) => {
                if (error.response.data === "The mail object is empty.") {
                    this.setError("Het onderdeel 'emailadres' kan niet leeg zijn. Vul een geldig adres in.")
                }
                if (error.response.data === "There are no roles selected for this user.") {
                    this.setError("U heeft geen organen geselecteerd voor deze gebruiker.")
                }
            })
        }
        if (this.state.modalTitle === 'Nieuwe gebruiker toevoegen') {
            let user_rollen = this.findSelectedOrganen();
            if (user_rollen.orgaan.length === 0) {
                this.setError("U heeft geen organen geselecteerd voor deze gebruiker.");
                return;
            }

            axios.post(
                "/user/add_user",
                {
                    user_name: (this.state.addFormName + ' ' + this.state.addFormLastName),
                    mail_adress: this.state.addFormMail,
                    user_password: sha256(this.state.addFormPassword),
                    user_rollen,
                    user_isAdmin: this.state.addFormIsAdmin,
                    user_instantie_id: this.state.user.instantie_id,
                },
                { withCredentials: true }
            ).then(() => {
                this.getAllUsers();
                this.closeModals();

            }).catch(error => {
                if (error.response.data === "Emailaddress is already in use.") {
                    this.setError("Het emailadres is al in gebruik bij een andere gebruiker")
                }
                if (error.response.data === "Username already exists.") {
                    this.setError("Er bestaat al een gebruiker met deze naam, pas de naam aan")
                }
            });
        }
        if (this.state.modalTitle === 'Nieuw orgaan toevoegen') {
            let leden = this.findSelectedUsers()
            if (leden.leden.length === 0) {
                this.setError("U heeft geen gebruikers geselecteerd voor dit orgaan.");
                return;
            }

            axios.post(
                "/rollen/add_orgaan",
                {
                    orgaan: {
                        name: this.state.addFormOrgaanName,
                        leden: leden,
                        instantie_id: this.state.user.instantie_id,
                    },
                },
                { withCredentials: true }
            ).then(() => {
                this.getAllUsers()
                this.closeModals();
            }).catch((error) => {
                if (error.response.data === "Rol already exists.") {
                    this.setError("Er bestaat al een orgaan met deze naam, pas de naam aan");
                    return;
                }
            });
        }
        if (this.state.modalTitle === "Orgaan bewerken") {
            let leden = this.findSelectedUsers();
            if (leden.leden.length === 0) {
                this.setError("U heeft geen gebruikers geselecteerd voor dit orgaan.");
                return;
            }

            axios.post(
                "/rollen/update_rol",
                {
                    orgaan: {
                        orgaan_id: this.state.addFormOrgaan.id,
                        leden: leden
                    },
                },
                { withCredentials: true }
            ).then(() => {
                this.getAllUsers();
                this.closeModals();
            })

        }
        this.getAllUsers();
    }



    setError(errorMessage) {
        this.setState({
            errorMessageToggle: true,
            errorMessage: errorMessage
        });
        const errorMessageToggleFalse = () => {
            this.setState({ errorMessageToggle: false });
        }
        setTimeout(errorMessageToggleFalse, 5000);
    }



 
    setOrganenNieuweGebruiker() {
        let orgaanRechtenForUser = this.state.yearPlanningOrganen.map(or => {
            return {
                orgaan_id: or.id,
                orgaan_name: or.rol,
                bekijker: 0,
                bewerker: 0,
            }
        });
        this.setState({ rechtenOrgaan: orgaanRechtenForUser });
    }
    setledenNieuwOrgaan() {
        let ledenRechtenForOrgaan = this.state.filteredUsers.map(user => {
            return {
                user_naam: user.naam,
                user_id: user.gebruiker_id,
                bekijker: 0,
                bewerker: 0,
            }
        }
        )
        this.setState({ ledenRechtenForOrgaan })

    }
    openAddEditModal(id, event) {
        this.setState({ userListState: '' })
        let modal = document.getElementById("add-edit-users-modal");
        modal.style.display = "block";
        modal.style.opacity = 1;

        this.resetAddUserFormFields();
    }
    openEditModal(id, event) {
        const users = this.state.users
        const organen = this.state.yearPlanningOrganen
        this.openAddEditModal();
        if (typeof id !== 'string') {
            this.setState({ editId: id })
            const orgaanToEdit = organen.find(x => x.id === id.id);
            const leden = users.filter(user => user.rol == id.rol)
            this.setState({
                addFormOrgaan: orgaanToEdit,
                addFormOrgaanName: orgaanToEdit.rol,
                addFormOrgaanUsers: leden,
            }, () => { this.calculateUsersVoorOrgaan() })
            this.getAllUsers()
        }
        else {
            this.setState({ editId: id })
            const userToEdit = this.state.filteredUsers.find(x => x.gebruiker_id === id);
            let rollen = (userToEdit.rol_id).split(',')
            let rechten = (userToEdit.rechten).split(',')

            this.setState({
                userToEdit: userToEdit,
                addFormName: userToEdit.naam,
                userId: userToEdit.gebruiker_id,
                addFormMail: userToEdit.emailadres,
                organen: rollen,
                rechten: rechten
            }, () => { this.calculateRechtenVoorUser() })
        }
    }
    openRemoveUserModal(user, event) {
        event.stopPropagation();
        this.setState({ removeId: user.gebruiker_id })
        this.setState({ removeUser: user.naam })
        this.setState({ removeIsAdmin: user.is_admin})
        let modal = document.getElementById("remove-confirm-modal");
        modal.style.display = "block";
        modal.style.opacity = 1;
    }
    handleRemoveUserAccepted() {
        let userId = this.state.removeId
        axios.post(
            "/user/remove_user",
            {
                id: userId,
            },
            { withCredentials: true }
        ).then((response) => {
            this.closeModals();
            this.getAllUsers()
        }).catch((error) => {
            if (error.response.data === "Something went wrong deleting this user.") {
                this.setError("Er is iets misgegaan bij het verwijderen van deze gebruiker.")
            }
        })
    }
9

    closeModals() {
        const body = document.querySelector('body')
        body.style.overflow = "auto"
        let addEditModal = document.getElementById("add-edit-users-modal");
        let removeConfirmModal = document.getElementById("remove-confirm-modal");
        addEditModal.style.display = "none";
        addEditModal.style.opacity = 0;
        removeConfirmModal.style.display = "none";
        removeConfirmModal.style.opacity = 0;

    }

    handleOnChangeAddUserForm(event) {
        if (event.target.name === 'addFormIsAdmin') {
            const name = event.target.name;
            const value = event.target.checked;
            this.setState({
                [name]: value
            })
        } else {
            const name = event.target.name;
            const value = event.target.value;
            this.setState({
                [name]: value
            })
        }
    }


    handleOnChangeUserRechten(selected, recht, orgaan, rechtenOrgaan) {

        let organen = rechtenOrgaan.map(o => {
            if (o.orgaan_id === orgaan) { // welk orgaan
                if (selected) { // is hij aan of uit
                    if (recht === 2) { // bewerker
                        if (o.bekijker === 1) { // is voor dit orgaan al een geselecteerd
                            o.bekijker = 0 // dan moet ie uit
                        }
                        o.bewerker = 1
                    }
                    if (recht === 3) {
                        if (o.bewerker === 1) {
                            o.bewerker = 0
                        }
                        o.bekijker = 1
                    }

                }
                if (!selected) {
                    if (recht === 2) { // bewerker
                        o.bewerker = 0
                    }
                    if (recht === 3) {
                        o.bekijker = 0
                    }
                }
            }
            return o;
        });
        this.setState({ rechtenOrgaan: organen })


    }
  


    calculateRechtenVoorUser() {
        let rechtenOrgaan = this.state.yearPlanningOrganen.map(or => {
            let orgIndex = this.state.organen.findIndex(o => o == or.id);
            if (orgIndex >= 0) {
                return {
                    orgaan_id: or.id,
                    orgaan_name: or.rol,
                    bekijker: this.state.rechten[orgIndex] == 3 ? 1 : 0,
                    bewerker: this.state.rechten[orgIndex] == 2 ? 1 : 0,
                }
            } else {
                return {
                    orgaan_id: or.id,
                    orgaan_name: or.rol,
                    bekijker: 0,
                    bewerker: 0,
                }
            }
        });
        this.setState({ rechtenOrgaan })
    }

    calculateUsersVoorOrgaan() {
        let ledenRechtenForOrgaan = this.state.filteredUsers.map(u => {
            let rollen = u.rol_id.split(',')
            let rechten = u.rechten.split(',')

            let rechtIndex = rollen.findIndex(r => r == this.state.addFormOrgaan.id);
            if (rechtIndex >= 0) {

                return {
                    user_name: u.naam,
                    user_id: u.gebruiker_id,
                    bekijker: rechten[rechtIndex] == 3 ? 1 : 0,
                    bewerker: rechten[rechtIndex] == 2 ? 1 : 0,
                }
            }
            else {
                return {
                    user_name: u.naam,
                    user_id: u.gebruiker_id,
                    bekijker: u.rechten == 0,
                    bewerker: u.rechten == 0,
                }
            }
        })


    this.setState({ ledenRechtenForOrgaan })
}

popupInputs() {
    // future use, for check gebruikers
    // const gebruikers = this.state.users
    // const admin = []
    // const bewerkers = []
    // const bekijkers = []
    // gebruikers.forEach((i, index) => {
    //     if (gebruikers[index].recht_naam === "Admin")
    //         admin.push(gebruikers[index])
    //     if (gebruikers[index].recht_naam === "Bewerker")
    //         bewerkers.push(gebruikers[index])
    //     if (gebruikers[index].recht_naam === "Bekijker")
    //         bekijkers.push(gebruikers[index])
    // })
    if (this.state.modalTitle === "Gebruiker bewerken") {
        return (
            <>
                <ul className="add-user-popup">
                    <div className="row">

                        <input type="text" placeholder="Naam" disabled value={this.state.addFormName} onChange={this.handleOnChangeAddUserForm} name="addFormName" ></input>
                        <input type="email" placeholder="Email" value={this.state.addFormMail} onChange={this.handleOnChangeAddUserForm} name="addFormMail"></input>


                    <div className={this.state.errorMessageToggle ? "error-message" : "error-message d-none"}>{this.state.errorMessage}</div>

                    <table>
                                <tr>
                                    <th>Orgaan</th>
                                    <th>Bekijker</th>
                                    <th>Bewerker</th>
                                </tr>

                        {this.state.rechtenOrgaan.map((o, index) => (

                            <tr>
                                <td>
                                    <p>{o.orgaan_name}</p>
                                </td>
                                <td>
                                    <input type="checkbox" checked={o.bekijker} onChange={(event) => this.handleOnChangeUserRechten(event.target.checked, 3, o.orgaan_id, this.state.rechtenOrgaan)} />
                                </td>
                                <td>
                                    <input type="checkbox" checked={o.bewerker} onChange={(event) => this.handleOnChangeUserRechten(event.target.checked, 2, o.orgaan_id, this.state.rechtenOrgaan)} />
                                </td>
                            </tr>
                        ))}
                    </table>
                        </div>

                </ul>
            </>
        )
    }


    if (this.state.modalTitle === "Nieuwe gebruiker toevoegen") {
        return (

            <ul className="add-user-popup">
                <div className="row">

                    <input type="text" placeholder="Initialen" value={this.state.addFormName} onChange={this.handleOnChangeAddUserForm} name="addFormName" ></input>
                    <input type="text" placeholder="Achternaam" value={this.state.addFormLastName} onChange={this.handleOnChangeAddUserForm} name="addFormLastName"></input>
                    <input type="email" placeholder="Email" value={this.state.addFormMail} onChange={this.handleOnChangeAddUserForm} name="addFormMail"></input>
                    <input type="password" placeholder="Wachtwoord" value={this.state.addFormPassword} onChange={this.handleOnChangeAddUserForm} name="addFormPassword"></input>
                        <div className="admin-row">
                   <div className="add-form-admin">
                    <label for="IsAdmin">Admin</label>
                    <input type="checkbox" id="IsAdmin" checked={this.state.addFormIsAdmin} onChange={this.handleOnChangeAddUserForm} name="addFormIsAdmin"></input>
                    </div>
                        </div>

                    <div className={this.state.errorMessageToggle ? "error-message" : "error-message d-none"}>{this.state.errorMessage}</div>

                    <table>
                        <tr>
                            <th>Orgaan</th>
                            <th>Bekijker</th>
                            <th>Bewerker</th>
                        </tr>

                        {this.state.rechtenOrgaan.map(o => (
                            <tr>
                                <td>
                                    <p>{o.orgaan_name}</p>
                                </td>
                                <td>
                                    <input type="checkbox" checked={o.bekijker} onChange={(event) => this.handleOnChangeUserRechten(event.target.checked, 3, o.orgaan_id, this.state.rechtenOrgaan)} />

                                </td>
                                <td>
                                    <input type="checkbox" checked={o.bewerker} onChange={(event) => this.handleOnChangeUserRechten(event.target.checked, 2, o.orgaan_id, this.state.rechtenOrgaan)} />
                                </td>
                            </tr>
                        ))}

                    </table>

                </div>
            </ul>
        )
    }


 
}
removeModalInputs() {
    if (this.state.modalTitle === "Weet u zeker dat u deze gebruiker wilt verwijderen?") {
        return (
            <div className="row">
                <div className="user-for-deletion">
                <ReactWarningIcon/>
                { this.state.removeIsAdmin ? <>  Let op: deze gebruiker heeft Admin-rechten <br /> </> : <>
                Als u deze verwijdering doorvoert worden alle persoonsgegevens verwijderd uit de systemen en worden de inlog rechten ontnomen van 
                    <p>{this.state.removeUser}</p> </> }
                </div>
                <div className={this.state.errorMessageToggle ? "error-message" : "error-message d-none"}>{this.state.errorMessage}</div>
                <input onClick={this.handleRemoveUserAccepted} className="button"
                    role="button" type="button" value="Ja" />
                <input onClick={this.closeModals} className="button gray" role="button"
                    type="button" value="Nee" />
            </div>
        )
    }
}
render() {
    if (this.state.user && !this.state.user.is_admin) {
        return (<p className="no-admin">{`Gebruiker ${this.state.user.naam} is geen admin`}</p>)
    }
    return (
        <>
            <Header titlePage="Overzicht gebruikers" backOption="1" />
            <div className="year-plannings">
                <div className="users-options">
                    <div onClick={event => this.openAddEditModal(undefined, event) + this.setState({ modalTitle: "Nieuwe gebruiker toevoegen" }) + this.setState({ modalButton: "Maak aan" }) + this.setOrganenNieuweGebruiker()} >
                        <button className="userbutton">
                            <ReactNewUsersIcon />
                            <p>Nieuwe gebruiker</p>
                        </button>
                    </div>

                </div>

                <div className="all-lists">

                    <table className="user-list">
                        <tbody>
                            <tr>
                                <th>Alle gebruikers</th>
                                <th>Admin</th>
                                <th>Orgaan</th>
                                <th>Wijzig</th>
                                <th>Verwijder</th>
                            </tr>
                            {this.userTableRows()}
                        </tbody>

                    </table>
                </div>
            
                <div id="add-edit-users-modal" className="modal">
                    <div className="modal-content">
                        <div className="modal-head">
                            {this.state.modalTitle}
                            <ReactCloseIcon onClick={this.closeModals} className="close-icon" />

                        </div>
                        <form onSubmit={this.handleSubmitNewUser} className="add-form-popup" >
                            <input type="hidden" name="addFormId" value={this.state.addFormId ? this.state.addFormId : ""} />
                            {this.popupInputs()}
                            <div className="row">
                                <input className="button" role="button" type="submit" value={this.state.modalButton} />
                            </div>
                        </form>
                        <div className="row">
                        </div>
                    </div>
                </div>
            </div>

            <div id="remove-confirm-modal" className="modal">
                <div className="modal-content">
                    <div className="modal-head">
                        {this.state.modalTitle}
                    </div>
                    <ReactCloseIcon onClick={this.closeModals} className="close-icon" />
                    <form action="">
                        {this.removeModalInputs()}

                    </form>
                </div>
            </div>
        </>
    )
}
}

export default Users;