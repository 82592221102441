import React, {Component} from "react";
import {EditText} from 'react-edit-text';
import {ReactComponent as ReactCloseIcon} from "../recources/icons/close-icon.svg"
import {ReactComponent as ReactDragIcon} from "../recources/icons/drag-icon.svg"
import {ReactComponent as ReactCrossIcon} from "../recources/icons/cross-icon.svg"
import {ReactComponent as ReactPlusIcon} from "../recources/icons/plus-icon.svg"
import {ReactComponent as ReactDotIcons} from "../recources/icons/Format_Icon_dot.svg"
import {ReactComponent as ReactLineTextIcons} from "../recources/icons/Format_Icon_text.svg"
import {ReactComponent as ReactLineTimeIcons} from "../recources/icons/Format_Icon_time.svg"
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import Sortly, {ContextProvider, useDrag, useDrop} from 'react-sortly';
import Header from "../Header";
import axios from "axios";
import moment from "moment";

class Format extends Component {

    getAgendaDataFromDatabase() {

        axios.post(
                "/format/get_format",
                {
                    id: this.state.loggedInUser.instantie_id,
                },
                { withCredentials: true }
            )
            .then((response) => {
                let newArray = []
                let activateBottomRow = true

                if (response.data.length === 0) {
                    this.setState({
                        agenda: newArray,
                    });
                    return
                }

                if (response.data[0].index_onderdeel !== 0) {
                    newArray.push({
                        id: "empty-onderdeel",
                        index_onderdeel: 0,
                        name: null,
                        time: null,
                        depth: 0
                    })
                    activateBottomRow = false
                }

                response.data.forEach(function (format, i) {
                    if (response.data[i - 1]) {
                        if (response.data[i]['index_onderdeel'] - response.data[i - 1]['index_onderdeel'] != 1) {
                            newArray.push({
                                id: "empty-onderdeel",
                                index_onderdeel: response.data[i]['index_onderdeel'] - 1,
                                name: null,
                                time: null,
                                depth: 0
                            })
                            activateBottomRow = false
                        }
                    }
                    newArray.push({
                        id: format.id,
                        index_onderdeel: format.index_onderdeel,
                        name: format.onderdeel_naam,
                        time: format.tijdsduur,
                        depth: 0
                    })
                })

                if (activateBottomRow) {
                    newArray.push({
                        id: "empty-onderdeel",
                        index_onderdeel: response.data.length,
                        name: null,
                        time: null,
                        depth: 0
                    })
                }

                this.setState({
                    agenda: newArray,
                });

            })
    }

    constructor(props) {
        super(props);
        this.state = {
            modalTitle: "Nieuwe jaarplanning aanmaken",
            agenda: [],
            errorMessageToggle: false,
            errorMessage: "",
            loggedInUser: JSON.parse(localStorage.getItem("914ebf336ee44d9deba3f19de35417ace22dc318b8a1024bcd9fe9b4f083a9ae")),
        }

        this.getAgendaDataFromDatabase();

        this.handleClickDelete = this.handleClickDelete.bind(this);
    }

    setError(errorMessage) {
        window.clearTimeout();
        this.setState({
            errorMessageToggle: true,
            errorMessage: errorMessage
        });
        const errorMessageToggleFalse = () => {
            this.setState({errorMessageToggle: false});
        }
        setTimeout(errorMessageToggleFalse, 5000);
    }

    closeModals() {
        let removeConfirmModal = document.getElementById("remove-confirm-modal");
        removeConfirmModal.style.display = "none";
        removeConfirmModal.style.opacity = 0;
    }

    handleClickDelete() {
        const id = this.state.onderdeelToRemoveId

        const removeFormat = () => {
            axios.post(
                "/format/remove_format_onderdeel",
                {
                    id: id,
                },
                {withCredentials: true}
            ).then(() => {
                this.setState({
                    onderdeelToRemoveId: null,
                })
                this.getAgendaDataFromDatabase();
                this.closeModals();
            });
        }

        let ids = []
        let indices = []
        const indexOfOnderdeelToRemove = this.state.agenda.find( ({ id }) => id === this.state.onderdeelToRemoveId).index_onderdeel;
        this.state.agenda.forEach(function (agenda) {
            if (agenda.index_onderdeel > indexOfOnderdeelToRemove && agenda.id) {
                ids.push(agenda.id)
                indices.push(agenda.index_onderdeel - 1)
            }
        })

        if (ids.length > 0) {
            let format_order = {
                ids: ids,
                indices: indices,
                instantie_id: this.state.loggedInUser.instantie_id
            }
            axios.post(
                "/format/update_format_onderdeel_indices",
                {
                    format_order: format_order,
                },
                {withCredentials: true}
            ).then(() => {
                removeFormat()
            })
        } else {
            removeFormat()
        }
    };

    render() {

        const ItemRenderer = (props) => {
            const {data: {id, name}} = props;
            const [, drag, preview] = useDrag();
            const [, drop] = useDrop();

            const openRemoveModal = () => {
                this.getAgendaDataFromDatabase()
                this.setState({
                    onderdeelToRemoveId: id,
                });
                let modal = document.getElementById("remove-confirm-modal");
                modal.style.display = "block";
                modal.style.opacity = 1;
            };

            const handleOnSaveFormatOnderdeel = (data) => {
                if (!data.value.replace(/\s/g, '').length) {
                    data.value = null;
                }
                const onderdeelToUpdate = {
                    id: id,
                    column: "naam",
                    value: data.value,
                }
                axios.post(
                    "/format/update_format_onderdeel",
                    {
                        format_onderdeel: onderdeelToUpdate,
                    },
                    {withCredentials: true}
                ).then(() => {
                    this.getAgendaDataFromDatabase();
                });
            }

            const handleOnSaveDuration = (data) => {

                if (isNaN(data.value)){
                    this.setError("Alleen nummers toegestaan. Wijziging is niet opgeslagen!")
                    return;
                } else if (data.value.length > 3) {
                    this.setError("Maximale duur is 999 minuten. Wijziging is niet opgeslagen!")
                    return;
                } else if (data.value < 0) {
                    this.setError("Een getal onder de nul is niet toegestaan. Wijziging is niet opgeslagen!")
                    return;
                }

                const duration = moment.utc(moment.duration(data.value, 'minutes').asMilliseconds()).format("HH:mm:ss");

                const formatToUpdate = {
                    id: id,
                    column: "tijdsduur",
                    value: duration,
                }
                axios.post(
                    "/format/update_format_onderdeel",
                    {
                        format_onderdeel: formatToUpdate,
                    },
                    {withCredentials: true}
                ).then(() => {
                    this.getAgendaDataFromDatabase();
                });
            }

            const durationField = () => {
                let duration = null;
                if (props.data.time && props.data.time != "00:00:00") {
                    duration = moment.duration(props.data.time).asMinutes().toString();
                }
                return (
                    <div className="duration">
                        <EditText defaultValue={duration}
                                  onSave={event => handleOnSaveDuration(event)}
                                  placeholder="... min"
                        />
                        <span className={duration ? "" : "d-none"}> min</span>
                    </div>
                )
            }


            const showRow = () => {
                if (props.id !== "empty-onderdeel") {
                    return (
                        <div ref={(ref) => drop(preview(ref))}>
                            <div className="drag-row" >
                                <span className="drag" ref={drag}><ReactDragIcon/> </span>
                                <span className="indexNumber">{props.index + 1}.</span>
                                <div><EditText defaultValue={name}
                                               onSave={event => handleOnSaveFormatOnderdeel(event)}
                                               placeholder="Onderdeel wijzigen"
                                />
                                </div>
                                <span className="remove" onClick={openRemoveModal}><ReactCrossIcon/></span>
                                <span className="mr-5 float-right">
                            {durationField()}
                        </span>
                            </div>
                        </div>
                    )
                } else {
                    return (
                        <div ref={(ref) => drop(preview(ref))}>
                            <div className="empty-onderwerp-rows">
                                <div className="index-icons"><ReactDotIcons/></div>
                                <div className="name-icons"><ReactLineTextIcons/></div>
                                <div className="remove-icons"><ReactDotIcons/></div>
                                <div className="time-icons"><ReactLineTimeIcons/></div>
                            </div>
                        </div>
                    )
                }
            }

            return (showRow())
        };

        const AgendaData = () => {
            const ITEMS = this.state.agenda.sort(function (a, b) {
                return a.index_onderdeel - b.index_onderdeel;
            });
            const [items, setItems] = React.useState(ITEMS);

            const handleChange = (newItems) => {
                setItems(newItems);

                //updating the index_onderwerp value in the database on the change of the user action
                const ids = []
                const indices = []

                newItems.forEach(function (item, index) {
                    ids.push(item.id)
                    indices.push(index)
                })

                const format_order = {
                    ids: ids,
                    indices: indices,
                    instantie_id: this.state.loggedInUser.instantie_id
                }

                axios.post(
                    "/format/update_format_onderdeel_indices",
                    {
                        format_order: format_order,
                    },
                    {withCredentials: true}
                )
            };

            const handleClickAdd = (placement) => {

                const addSingleFormat = () => {

                    const formattedFormatData = {
                        instantie_id: this.state.loggedInUser.instantie_id,
                        index_onderdeel: index,
                        naam: "",
                    }

                    axios.post(
                        "/format/add_format_onderdeel",
                        {
                            format_onderdeel: formattedFormatData,
                        },
                        {withCredentials: true}
                    ).then(() => {
                        this.getAgendaDataFromDatabase();
                    });
                }


                let index;
                if (placement === "bottom") {
                    if (this.state.agenda.length === 0) {
                        index = 1
                    } else {
                        index = Math.max.apply(Math, this.state.agenda.map(function (o) {return o.index_onderdeel;})) + 1
                    }
                    addSingleFormat()
                } else {
                    index = 0
                    let ids = []
                    let indices = []

                    items.forEach(function (agenda, index) {
                        ids.push(agenda.id)
                        indices.push(index + 1)
                    })

                    if (ids.length > 0) {
                        let format_order = {
                            ids: ids,
                            indices: indices,
                            instantie_id: 1
                        }

                        axios.post(
                            "/format/update_format_onderdeel_indices",
                            {
                                format_order: format_order,
                            },
                            {withCredentials: true}
                        ).then((response) => {
                            addSingleFormat()
                        })
                    } else {
                        addSingleFormat()
                    }
                }

            };

            const addOnderdeelButton = (placement) => {
                return (
                    <div onClick={event => handleClickAdd(placement)} className="addTopic addPart"><ReactPlusIcon/>Nieuw
                        onderdeel toevoegen

                    </div>
                );
            }

            const noFormatItems = () => {
                if (this.state.agenda.length === 0) {
                    return (
                        <div className="empty-onderwerp-rows">
                            <div className="index-icons"><ReactDotIcons/></div>
                            <div className="name-icons"><ReactLineTextIcons/></div>
                            <div className="remove-icons"><ReactDotIcons/></div>
                            <div className="time-icons"><ReactLineTimeIcons/></div>
                        </div>
                    )
                }
            }

            return (
                <div>

                    <div className="tijdlijn">Start vergadering</div>

                    {addOnderdeelButton("top")}

                    {noFormatItems()}

                    <Sortly maxDepth={0} items={items} onChange={handleChange}>
                        {(props) => <ItemRenderer
                            {...props}
                        />}
                    </Sortly>

                    {addOnderdeelButton("bottom")}
                    <div className="tijdlijn-after">Einde vergadering</div>

                </div>
            );
        };

        return (
            <>
                <Header titlePage="Standaard agenda punten" backOption="1" formatOption="0"/>

                <div className="year-plannings" style={{padding: '30px 100px 40px 80px'}}>

                    <DndProvider backend={HTML5Backend}>
                        <ContextProvider>
                            <AgendaData/>
                        </ContextProvider>
                    </DndProvider>

                    <div className={this.state.errorMessageToggle ? "error-message" : "error-message d-none"}>
                        {this.state.errorMessage}
                    </div>

                    <div id="remove-confirm-modal" className="modal">
                        <div className="modal-content">
                            <div className="modal-head">
                                Weet u zeker dat u dit wilt verwijderen?
                            </div>
                            <ReactCloseIcon onClick={this.closeModals} className="close-icon"/>
                            <form action="">
                                <div className="row">
                                    <div className="col-sm-6 m-auto d-flex">
                                        <input onClick={this.handleClickDelete} className="button" role="button"
                                               type="button" value="Ja"/>
                                    </div>
                                    <div className="col-sm-6 m-auto d-flex">
                                        <input onClick={this.closeModals} className="button gray" role="button"
                                               type="button" value="Nee"/>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Format;