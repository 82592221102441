import React, { Component } from "react";

import Header from "../Header";
import axios from "axios";
import { ReactComponent as ReactEditIcon } from "../recources/icons/edit-icon.svg"
import { ReactComponent as ReactDeleteIcon } from "../recources/icons/delete-icon.svg"
import { ReactComponent as ReactNewVerantwoordelijkeIcon } from "../recources/icons/verantwoordelijk-icon.svg"
import { ReactComponent as ReactCloseIcon } from "../recources/icons/close-icon.svg"
import { ReactComponent as ReactCheckIcon } from "../recources/icons/check-icon.svg"
import { ReactComponent as ReactWarningIcon } from "../recources/icons/warning.svg"
import { ReactComponent as ReactReturnIcon } from "../recources/icons/return-icon.svg"


class Verantwoordelijken extends Component {

    getAllVerantwoordelijken() {
        axios.post("/verantwoordelijke/get_verantwoordelijken", 
        {
            instantie_id: this.state.user.instantie_id
        },
        {
            withCredentials: true,
        }).then((response) => {
            this.setState({
                verantwoordelijken: response.data,
            });
        }).catch((error) => {
            console.log("task fetching error", error);
        });
    }


    constructor(props) {
        super(props);
        this.state = {
            addFormId: '',
            addFormName: "",
            removeId: 0,
            modalTitle: "",
            user: JSON.parse(localStorage.getItem("914ebf336ee44d9deba3f19de35417ace22dc318b8a1024bcd9fe9b4f083a9ae")),
            modalButton: '',
            verantwoordelijken: [],
            errorMessageToggle: false,
            editMode: '',
            editVerantwoordelijke: '',
        }

        this.getAllVerantwoordelijken();
        this.handleOnChangeAddverantwoordelijkeForm = this.handleOnChangeAddverantwoordelijkeForm.bind(this);
        this.handleSubmitNewVerantwoordelijke = this.handleSubmitNewVerantwoordelijke.bind(this);
        this.handleRemoveVerantwoordelijkeAccepted = this.handleRemoveVerantwoordelijkeAccepted.bind(this)
        this.handleCancelVerantwoordelijke = this.handleCancelVerantwoordelijke.bind(this)
    }

    verantwoordelijkenTableRows() {
        return (
            <>
                {this.state.verantwoordelijken.map((verantwoordelijke, index) => (
                    <tr key={index}>
                        {
                            this.state.editMode === verantwoordelijke.rol ? 
                                <td>
                                    <input onChange={event => this.handleChangeVerantwoordlijke(event)} type="text" value={this.state.editVerantwoordelijke} name="addFormName" ></input>
                                </td> :
                                <td>{verantwoordelijke.rol}</td>
                        }
                        {
                            this.state.editMode === verantwoordelijke.rol ?  
                                <td className="edit-verantwoordelijke-row"> 
                                    <ReactCheckIcon onClick={event => { this.handleConfirmVerantwoordelijkeChange(verantwoordelijke.rol, verantwoordelijke.id) }} />  
                                </td> : 
                                <td className="edit-table-row" onClick={() => { this.setState({ editMode: verantwoordelijke.rol, editVerantwoordelijke: verantwoordelijke.rol }) }}>  
                                    <ReactEditIcon />
                                </td>
                        }
                        {
                            this.state.editMode === verantwoordelijke.rol ?  
                                <td className="delete-table-row"> 
                                    <ReactReturnIcon onClick={() => { this.handleCancelVerantwoordelijke(verantwoordelijke.rol, verantwoordelijke.id) }} />  
                                </td> :
                                <td className="delete-table-row">
                                    <ReactDeleteIcon onClick={event => {
                                        this.openRemoveVerantwoordelijkeModal(verantwoordelijke, event);
                                        this.setState({ modalTitle: 'Weet u zeker dat u deze verantwoordelijke wilt verwijderen?' });
                                    }} />
                                </td>
                        }
                    
                    </tr>
                )
                )}
            </>
        )
    }

    handleSubmitNewVerantwoordelijke(e) {
        e.preventDefault();
        if (this.state.modalTitle === 'Nieuwe verantwoordelijke toevoegen') {
            axios.post(
                "/verantwoordelijke/add_verantwoordelijke",
                {
                    instantie_id: this.state.user.instantie_id,
                    verantwoordelijke: this.state.addFormName,
                },
                { withCredentials: true }
            ).then(() => {
                this.getAllVerantwoordelijken();
                this.closeModals();

            }).catch(error => {
                if (error.response.data === "Verantwoordelijke already exists") {
                    this.setError("De verantwoordelijke met deze naam bestaat al.")
                }
            });
        }
        this.getAllVerantwoordelijken();
    }

    setError(errorMessage) {
        this.setState({
            errorMessageToggle: true,
            errorMessage: errorMessage
        });
        const errorMessageToggleFalse = () => {
            this.setState({ errorMessageToggle: false });
        }
        setTimeout(errorMessageToggleFalse, 5000);
    }

    openAddEditVerantwoordelijkeModal() {
        let modal = document.getElementById("add-edit-verantwoordelijke-modal");
        modal.style.display = "block";
        modal.style.opacity = 1;
    }

    openRemoveVerantwoordelijkeModal(verantwoordelijke, event) {
        event.stopPropagation();
        this.setState({ removeId: verantwoordelijke.id })
        let modal = document.getElementById("remove-confirm-modal");
        modal.style.display = "block";
        modal.style.opacity = 1;
    }

    handleRemoveVerantwoordelijkeAccepted() {
        let verantwoordelijke_id = this.state.removeId
        axios.delete(
            "/verantwoordelijke/remove_verantwoordelijke",
            {
                data: {
                    id: verantwoordelijke_id,
                    instantie_id: this.state.user.instantie_id,
                }
            },
            { withCredentials: true }
        ).then(() => {
            this.closeModals();
            this.getAllVerantwoordelijken()
        })
    }

    closeModals() {
        const body = document.querySelector('body')
        body.style.overflow = "auto"
        let addEditModal = document.getElementById("add-edit-verantwoordelijke-modal");
        let removeConfirmModal = document.getElementById("remove-confirm-modal");
        addEditModal.style.display = "none";
        addEditModal.style.opacity = 0;
        removeConfirmModal.style.display = "none";
        removeConfirmModal.style.opacity = 0;

    }

    handleOnChangeAddverantwoordelijkeForm(event) {
        const name = event.target.name;
        const value = event.target.value;
        console.log(`name is ${name} and value is ${value}`)
        this.setState({
            [name]: value
        })
    }

    handleChangeVerantwoordlijke(event, oldName) {
        // this.setState({ editOldVerantwoordelijke: oldName })
        this.setState({ editVerantwoordelijke: event.target.value })

    }
    handleConfirmVerantwoordelijkeChange(username, verantwoordelijke_id){
        this.setState({ 
            editMode: '',
            editVerantwoordelijke: '',
        })

        axios.post(
            "/verantwoordelijke/update_verantwoordelijke",
            {
                instantie_id : this.state.user.instantie_id,
                rol: this.state.editVerantwoordelijke,
                id: verantwoordelijke_id,
            },
            { withCredentials: true }
        ).then(() => {
            this.getAllVerantwoordelijken();
            this.closeModals();
        }).catch((error) => {
            if (error.response.data === "Something went wrong updating this verantwoordelijke.") {
                this.setError("Er ging iets mis met het aanpassen van de verantwoordelijke.")
            }
            if (error.response.data === "Not all necessary parameters given for query.") {
                this.setError("Niet alle gegevens zijn aanwezig om de verantwoordelijke te kunnen aanpassen.")
            }
        })
    }

    handleCancelVerantwoordelijke() {
        this.setState({ editMode: 'jo' })
        console.log(`edit mode: ${this.state.editMode}`)
    }

    popupInputs() {
        if (this.state.modalTitle === "Nieuwe verantwoordelijke toevoegen") {
            return (
                <ul className="add-verantwoordelijke-popup">
                    <div className="row">
                    <div className={this.state.errorMessageToggle ? "error-message" : "error-message d-none"}>{this.state.errorMessage}</div>
                        <input type="text" placeholder="Naam verantwoordelijke" value={this.state.addFormName} onChange={this.handleOnChangeAddverantwoordelijkeForm} name="addFormName" ></input>
                        <div className={this.state.errorMessageToggle ? "error-message" : "error-message d-none"}>{this.state.errorMessage}</div>
                    </div>
                </ul>
            )
        }
    }

    removeModalInputs() {
         if (this.state.modalTitle === "Weet u zeker dat u deze verantwoordelijke wilt verwijderen?") {
            return (
                <div className="row">
                    <div className="user-for-deletion">
                        <ReactWarningIcon />
                        Deze verantwoordelijke verdwijnt uit alle jaarplanningen waar deze is toegewezen.
                    </div>
                    <div className={this.state.errorMessageToggle ? "error-message" : "error-message d-none"}>{this.state.errorMessage}</div>
                    <div className="col-sm-6 m-auto d-flex">
                        <input onClick={this.handleRemoveVerantwoordelijkeAccepted} className="button"
                            role="button" type="button" value="Ja" />
                    </div>
                    <div className="col-sm-6 m-auto d-flex">
                        <input onClick={this.closeModals} className="button gray" role="button"
                            type="button" value="Nee" />
                    </div>
                </div>
            )
        }
    }

    render() {
        if (this.state.user && !this.state.user.is_admin) {
            return (<p className="no-admin">{`Gebruiker ${this.state.user.naam} is geen admin`}</p>)
        }
        return (
            <>
                <Header titlePage="Overzicht verantwoordelijken" backOption="1" />
                <div className="year-plannings">
                    {/* Button to add a new Verantwoordelijke */}
                    <div className="users-options">
                        <div onClick={() => {
                                        this.openAddEditVerantwoordelijkeModal(); 
                                        this.setState({ 
                                            modalTitle: "Nieuwe verantwoordelijke toevoegen", 
                                            modalButton: "Voeg toe",
                                        });
                                    }} >
                            <button className="userbutton">
                                <ReactNewVerantwoordelijkeIcon />
                                <p>Nieuwe Verantwoordelijke</p>
                            </button>
                        </div>
                    </div>

                    {/* List of Verantwoordelijken. With options to change and add them */}
                    <div className="all-lists">
                        <table className="user-list">
                            <thead>
                                <tr>
                                    <th>Verantwoordelijke</th>
                                        {this.state.editMode !== '' ? <th>Opslaan</th> : <th>Wijzig</th>}
                                        {this.state.editMode !== '' ? <th>Annuleren</th> : <th>Verwijder
                                    </th>}
                                </tr>
                            </thead>
                            <tbody>
                                {this.verantwoordelijkenTableRows()}
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* Modal to add a Verantwoordelijke */}
                <div id="add-edit-verantwoordelijke-modal" className="modal">
                        <div className="modal-content">
                            <div className="modal-head">
                                {this.state.modalTitle}
                                <ReactCloseIcon onClick={this.closeModals} className="close-icon" />
                            </div>
                            <form onSubmit={this.handleSubmitNewVerantwoordelijke} className="add-form-popup" >
                                <input type="hidden" name="addFormId" value={this.state.addFormId ? this.state.addFormId : ""} />
                                {this.popupInputs()}
                                <div className="row">
                                    <input className="button" role="button" type="submit" value={this.state.modalButton} />
                                </div>
                            </form>
                            <div className="row">
                            </div>
                        </div>
                </div>

                {/* Modal to delete a Verantwoordelijke */}
                <div id="remove-confirm-modal" className="modal">
                    <div className="modal-content">
                        <div className="modal-head">
                            {this.state.modalTitle}
                        </div>
                        <ReactCloseIcon onClick={this.closeModals} className="close-icon" />
                        <form action="">
                            {this.removeModalInputs()}
                        </form>
                    </div>
                </div>
            </>
        )
    }
}

export default Verantwoordelijken;