import React, {Component} from "react";
import Moment from "react-moment"
import moment from 'moment';
import localization from 'moment/locale/nl';
import 'react-dates/initialize';
import {SingleDatePicker} from 'react-dates';
import TimeField from 'react-simple-timefield';
import {ReactComponent as ReactAddIcon} from "../recources/icons/add-icon.svg"
import {ReactComponent as ReactEditIcon} from "../recources/icons/edit-icon.svg"
import {ReactComponent as ReactCloseIcon} from "../recources/icons/close-icon.svg"
import Header from "../Header";
import axios from "axios";

class Home extends Component {

    getAllAgendasFromDatabase() {
        axios.post(
            "/agenda/get_agendas",
            {
                ids: [1, 2],
                rollen: [1, 2],
            },
            {withCredentials: true}
        ).then((response) => {
            this.setState({
                agendas: response.data,
            });
        });
    }

    constructor(props) {
        super(props);
        this.state = {
            modalTitle: "Nieuwe jaarplanning aanmaken",
            modalButton: "Maak aan",
            agendas: "",
            types: [],
            organen: [],
            form: {
                id: 0,
                type: "",
                orgaan: "",
                instantie: "",
                start_time: "",
                end_time: "",
                date: "",
            },
            focused: false,
            removeId: null,
            errorMessageToggle: false,
            errorMessage: "",
        }

        moment.locale('nl', localization);

        this.getAllAgendasFromDatabase();

        axios.get("/agenda/get_types", {
            withCredentials: true,
        }).then((response) => {
            this.setState({
                types: response.data,
            });
        });

        axios.get("/rollen/get_organen", {
            withCredentials: true,
        }).then((response) => {
            this.setState({
                organen: response.data,
            });
        });

        this.openAddEditModal = this.openAddEditModal.bind(this);
        this.handleOnChangeAgendaForm = this.handleOnChangeAgendaForm.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.handleSubmitAddAgenda = this.handleSubmitAddAgenda.bind(this);
        this.handleRemoveAgendaAccepted = this.handleRemoveAgendaAccepted.bind(this);
    }

    setError(errorMessage) {
        window.clearTimeout();
        this.setState({
            errorMessageToggle: true,
            errorMessage: errorMessage
        });
        const errorMessageToggleFalse = () => {
            this.setState({errorMessageToggle: false});
        }
        setTimeout(errorMessageToggleFalse, 5000);
    }

    resetFormFields() {
        this.setState({
            form: {
                id: null,
                type: "",
                orgaan: "",
                instantie: "",
                start_time: "",
                end_time: "",
                date: "",
            }
        })
    }

    openAddEditModal(id, event) {
        event.stopPropagation();
        if (typeof id !== 'string') {
            this.setState({modalTitle: "Nieuwe agenda aanmaken"});
            this.setState({modalButton: "Maak aan"});
            this.resetFormFields();
        } else {
            this.setState({modalTitle: "Agenda wijzingen"});
            this.setState({modalButton: "Wijzingen"});
            const agendaToEdit = this.state.agendas.find(x => x.id === id);
            const agendaOrgaanToEdit = this.state.organen.find(x => x.rol === agendaToEdit.orgaan).id;
            const agendaTypeToEdit = this.state.types.find(x => x.naam === agendaToEdit.type_agenda).id;
            const agendaDateToEdit = moment(agendaToEdit.datum);
            this.setState({
                form: {
                    id: agendaToEdit.id,
                    type: agendaTypeToEdit,
                    orgaan: agendaOrgaanToEdit,
                    instantie: 1,
                    start_time: agendaToEdit.start_tijd,
                    end_time: agendaToEdit.eind_tijd,
                    date: agendaDateToEdit,
                }
            });
        }
        let modal = document.getElementById("add-edit-year-planning-modal");
        modal.style.display = "block";
        modal.style.opacity = 1;
    }

    openRemoveModal(id, event) {
        event.stopPropagation();
        this.setState({removeId: id})
        let modal = document.getElementById("remove-confirm-modal");
        modal.style.display = "block";
        modal.style.opacity = 1;
    }

    closeModals() {
        let addEditModal = document.getElementById("add-edit-year-planning-modal");
        let removeConfirmModal = document.getElementById("remove-confirm-modal");
        addEditModal.style.display = "none";
        addEditModal.style.opacity = 0;
        removeConfirmModal.style.display = "none";
        removeConfirmModal.style.opacity = 0;
    }

    agendaTableRows() {
        const agendas = this.state.agendas;

        return (
            <tbody>
            {Object.keys(agendas).map(key => {
                const startTime = moment(agendas[key].start_tijd, "HH:mm:ss");
                const endTime = moment(agendas[key].eind_tijd, "HH:mm:ss");
                return (
                    <tr key={agendas[key].id} onClick={() => this.props.history.push('/agenda/' + agendas[key].id)}>
                        <td>{agendas[key].type_agenda}</td>
                        <td>{agendas[key].orgaan}</td>
                        <td>{agendas[key].instantie} {agendas[key].logo_klein ? "[logo]" : ""}</td>
                        <td><Moment format="DD MMM YYYY" date={agendas[key].datum}/></td>
                        <td><Moment date={startTime} format="HH:mm"/> - <Moment date={endTime} format="HH:mm"/></td>
                        <td onClick={event => this.openAddEditModal(agendas[key].id, event)}><ReactEditIcon/></td>
                        <td onClick={event => this.openRemoveModal(agendas[key].id, event)}
                              className="delete-row"></td>
                    </tr>
                )
            })}
            </tbody>
        )
    }

    handleOnChangeAgendaForm(event) {
        const name = event.target.name;
        const value = event.target.value;
        const newFormData = this.state.form;
        newFormData[name] = value;
        this.setState({
            form: newFormData
        })
    }

    formYearPlanningTypesSelectField() {
        const types = this.state.types;
        return (
            <select required value={this.state.form.type} onChange={this.handleOnChangeAgendaForm}
                    className="form-control" name="type">
                <option value="">Type bestand</option>
                {types.map(value => {
                    return (<option key={value.id} value={value.id}>{value.naam}</option>)
                })}
            </select>
        )
    }

    formYearPlanningOrganenSelectField() {
        const organen = this.state.organen;
        return (
            <select required value={this.state.form.orgaan} onChange={this.handleOnChangeAgendaForm}
                    className="form-control" name="orgaan">
                <option value="">Naam bestand</option>
                {organen.map(value => {
                    return (<option key={value.id} value={value.id}>{value.rol}</option>)
                })}
            </select>
        )
    }

    onChangeDate(dateData) {
        const newFormData = this.state.form;
        newFormData.date = dateData;
        this.setState({
            form: newFormData
        })
    }

    setTime(data, timeType) {
        const newFormData = this.state.form;
        newFormData[timeType] = data.target.defaultValue;
        this.setState({
            form: newFormData
        })
    }

    handleSubmitAddAgenda(e) {
        e.preventDefault();

        if (moment(this.state.form.date).format() === "Invalid date"){
            this.setError("Datum veld is geen correcte datum. Wijziging is niet opgeslagen!");
            return;
        } else if (moment(this.state.form.end_time, 'HH:mm').isBefore(moment(this.state.form.start_time, 'HH:mm'))) {
            this.setError("Eindtijd kan niet eerder dan begintijd zijn. Wijziging is niet opgeslagen!");
            return;
        }

        if (this.state.form.id === null) {
            const formattedFormData = {
                instantie_id: this.state.form.instantie,
                type_id: parseInt(this.state.form.type),
                orgaan_id: parseInt(this.state.form.orgaan),
                datum: moment(this.state.form.date).format(),
                start_tijd: moment(this.state.form.start_time, 'HH:mm').format('HH:mm:ss'),
                eind_tijd: moment(this.state.form.end_time, 'HH:mm').format('HH:mm:ss'),
            }
            axios.post(
                "/agenda/add_agenda",
                {
                    agenda: formattedFormData,
                    rechten: [1, 2],
                    rollen: [1, 2],
                },
                {withCredentials: true}
            ).then(() => {
                this.resetFormFields();
                this.getAllAgendasFromDatabase();
                this.closeModals();
            });

        } else {
            const formattedFormData = {
                id: this.state.form.id,
                instantie_id: this.state.form.instantie,
                type_id: parseInt(this.state.form.type),
                orgaan_id: parseInt(this.state.form.orgaan),
                datum: moment(this.state.form.date).format(),
                start_tijd: moment(this.state.form.start_time, 'HH:mm').format('HH:mm:ss'),
                eind_tijd: moment(this.state.form.end_time, 'HH:mm').format('HH:mm:ss'),
            }
            const toEditVariables = ["instantie_id", "type_id", "orgaan_id", "datum", "start_tijd", "eind_tijd"];

            toEditVariables.map(toEdit => {
                axios.post(
                    "/agenda/update_agenda",
                    {
                        agenda: {
                            id: this.state.form.id,
                            column: toEdit,
                            value: formattedFormData[toEdit],
                        },
                    },
                    {withCredentials: true}
                ).then(() => {
                    this.resetFormFields();
                    this.getAllAgendasFromDatabase();
                    this.closeModals();
                });
            })
        }
    }

    handleRemoveAgendaAccepted() {
        const agendaIdToRemove = this.state.removeId;
        axios.post(
                "/agenda/remove_agenda",
                {
                    id: agendaIdToRemove,
                },
                { withCredentials: true }
            ).then(() => {
                this.getAllAgendasFromDatabase();
                this.closeModals();
            });
    }

    render() {

        const onClickTimeField = (element) => {
            if (element.target.selectionStart === 5) {
                element.target.setSelectionRange(0, 0);
            }
        }

        return (
            <>
                <Header titlePage="Agenda overzicht"/>
                <a href="/#/format" className="formatbutton">Format</a>
                <div className="year-plannings">
                    <table className="table overview">
                        <thead>
                        <tr>
                            <th scope="col">Type</th>
                            <th scope="col">Orgaan</th>
                            <th scope="col">Instantie</th>
                            <th scope="col">Datum</th>
                            <th scope="col">Tijd</th>
                            <th scope="col" style={{width: "75px"}}>Wijzig</th>
                        </tr>
                        </thead>
                        {this.agendaTableRows()}
                    </table>

                    <div onClick={event => this.openAddEditModal(undefined, event)} className="new-year-planning">
                        <ReactAddIcon/>Nieuwe agenda aanmaken
                    </div>

                    <div id="add-edit-year-planning-modal" className="modal">
                        <div className="modal-content">
                            <div className="modal-head">
                                {this.state.modalTitle}
                            </div>
                            <ReactCloseIcon onClick={this.closeModals} className="close-icon"/>
                            <form onSubmit={this.handleSubmitAddAgenda}>
                                <div className="row">
                                    <div className="col-md-6">
                                        {this.formYearPlanningTypesSelectField()}
                                    </div>
                                    <div className="col-md-6">
                                        {this.formYearPlanningOrganenSelectField()}
                                    </div>
                                    <div className="col-md-6">
                                        <select required value={this.state.form.instantie}
                                                onChange={this.handleOnChangeAgendaForm} name="instantie"
                                                className="form-control">
                                            <option value="">Instantie</option>
                                            <option value="1">HNPF</option>
                                        </select>
                                    </div>
                                    {/*<div className="col-md-6">*/}
                                    {/*    <input type="text" className="form-control" placeholder="Logo"/>*/}
                                    {/*</div>*/}
                                    <div className="col-md-6">
                                        <SingleDatePicker
                                            required
                                            date={this.state.form.date ? this.state.form.date : null}
                                            onDateChange={date => this.onChangeDate(date)}
                                            focused={this.state.focused}
                                            onFocusChange={({focused}) => this.setState({focused})}
                                            placeholder="Datum (DD/MM/JJJJ)"
                                            displayFormat={() => "DD/MM/YYYY"}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <div className="time-label">Aanvang</div>
                                        <TimeField
                                            onClick={element => onClickTimeField(element)}
                                            required
                                            value={this.state.form.start_time}
                                            onChange={value => {
                                                this.setTime(value, "start_time")
                                            }}
                                            input={<input type="text" className="form-control"/>}
                                            colon=":"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <div className="time-label">Einde</div>
                                        <TimeField
                                            onClick={element => onClickTimeField(element)}
                                            required
                                            value={this.state.form.end_time}
                                            onChange={value => {
                                                this.setTime(value, "end_time")
                                            }}
                                            input={<input type="text" className="form-control"/>}
                                            colon=":"
                                        />
                                    </div>
                                    {/*<div className="col-md-6">*/}
                                    {/*    <input type="text" className="form-control" placeholder="Weergaverechten"/>*/}
                                    {/*</div>*/}
                                    {/*<div className="col-md-6">*/}
                                    {/*    <input type="text" className="form-control" placeholder="Bewerkingsrechten"/>*/}
                                    {/*</div>*/}
                                </div>
                                <div className="row">
                                    <input className="button" role="button" type="submit"
                                           value={this.state.modalButton}/>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div id="remove-confirm-modal" className="modal">
                        <div className="modal-content">
                            <div className="modal-head">
                                Weet u zeker dat u deze agenda wilt verwijderen?
                            </div>
                            <ReactCloseIcon onClick={this.closeModals} className="close-icon"/>
                            <form action="">
                                <div className="row">
                                    <div className="col-sm-6 m-auto d-flex">
                                        <input onClick={this.handleRemoveAgendaAccepted} className="button" role="button" type="button" value="Ja"/>
                                    </div>
                                    <div className="col-sm-6 m-auto d-flex">
                                        <input onClick={this.closeModals} className="button gray" role="button"
                                               type="button" value="Nee"/>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className={this.state.errorMessageToggle ? "error-message" : "error-message d-none"}>{this.state.errorMessage}</div>
            </>
        );
    }
}

export default Home;