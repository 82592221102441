//'root page' go to login if not logged in, else redirected to the home page
//or where the user left on, if there is one
import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./Home";
import YearPlannings from "./pages/year-plannings";
import YearPlanning from "./pages/year-planning";
import UserOverview from "./pages/user-overview";
import OrgaanOverview from "./pages/orgaan-page";
import VerantwoordelijkeOverview from "./pages/verantwoordelijken-page";

import Agendas from "./pages/agendas";
import Agenda from "./pages/agenda";
// import Profile from "./Profile";
import Login from "./Login";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import NavBar from "./NavBar";
import "./../../index.css";
import "./recources/sass/app.scss";
// import Axios_tests from "./Axios_tests";
import EmptyPage from "./pages/emptyPage";
import FormatPage from "./pages/formatPage";
import PrintProvider, { Print, NoPrint } from 'react-easy-print';

export default class App extends Component {
  constructor() {
    super();

    this.state = {
      loggedInStatus: "NOT_LOGGED_IN",
      user: {},
    };
    this.handleLogin = this.handleLogin.bind(this);
    this.handleSuccessfulAuth = this.handleSuccessfulAuth.bind(this);
  }

  handleLogin(data) {
    this.setState({
      loggedInStatus: "LOGGED_IN",
      user: data,
    });
  }
  handleSuccessfulAuth(data) {
    this.handleLogin(data);
  }
  render() {
    const loggedInUser = localStorage.getItem("914ebf336ee44d9deba3f19de35417ace22dc318b8a1024bcd9fe9b4f083a9ae");
    return JSON.parse(loggedInUser) ? (
        <PrintProvider>
        <NoPrint>
        <Router>
          <div className="App page-container">
            <NavBar />
            <div className="main">
              <Switch>
                <Route
                  path="/home"

                  render={(props) => (
                    <Home
                      {...props}
                      handleLogin={this.handleLogin}
                      loggedInStatus={this.state.loggedInStatus}
                    />
                  )}
                />
                <Route
                  path="/"
                  exact
                  render={(props) => (
                    <YearPlannings
                      {...props}
                      loggedInStatus={this.state.loggedInStatus}
                    />
                  )}
                />

                <Route path="/YearPlanning/:id" component={YearPlanning} />

                <Route
                  path="/Agendas"
                  render={(props) => (
                    <Agendas
                      {...props}
                      loggedInStatus={this.state.loggedInStatus}
                    />
                  )}
                />
                <Route path="/agenda/:id" component={Agenda} />
                <Route
                  path="/Profile"
                  render={(props) => (
                    <EmptyPage
                      {...props}
                      loggedInStatus={this.state.loggedInStatus}
                    />
                  )}
                />
                <Route
                  path="/format"
                  render={(props) => (
                    <FormatPage
                      {...props}
                      loggedInStatus={this.state.loggedInStatus}
                    />
                  )}
                />
                <Route
                  path="/Record"
                  render={(props) => (
                    <EmptyPage
                      {...props}
                      loggedInStatus={this.state.loggedInStatus}
                    />
                  )}
                />
                <Route
                  path="/Gebruikers"
                  render={(props) => (
                    <UserOverview
                      {...props}
                      loggedInStatus={this.state.loggedInStatus}
                    />
                  )}
                />
                   <Route
                  path="/Organen"
                  render={(props) => (
                    <OrgaanOverview
                      {...props}
                      loggedInStatus={this.state.loggedInStatus}
                    />
                  )}
                />
                        <Route
                  path="/Verantwoordelijken"
                  render={(props) => (
                    <VerantwoordelijkeOverview
                      {...props}
                      loggedInStatus={this.state.loggedInStatus}
                    />
                  )}
                />
                {/*<Route component={PageNotFound} />*/}
              </Switch>
            </div>
          </div>
        </Router>
        </NoPrint>
        </PrintProvider>
    ) : (
      <Login handleSuccessfulAuth={this.handleSuccessfulAuth} />
    );
  }
}
