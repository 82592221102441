// navigation bar on the side of the page
import "./../../index.css";
import React, {createRef} from "react";
import {Link} from "react-router-dom";
import axios from "axios";

import floxxLogo from './recources/images/floxx-meetings-logo-small.png';
import profilePicture from './recources/images/HNPF-logo.png'
import {ReactComponent as ReactJaarPlanningIcon} from "./recources/icons/2021-icon.svg"
import {ReactComponent as ReactVergaderIcon} from "./recources/icons/vergader-icon.svg"
import {ReactComponent as ReactStukkenIcon} from "./recources/icons/stukken-icon.svg"
import {ReactComponent as ReactAgendaIcon} from "./recources/icons/agenda-icon.svg"
import {ReactComponent as ReactSignOutIcon} from "./recources/icons/sign-out-icon.svg"
import {ReactComponent as ReactUsersIcon} from "./recources/icons/users-icon.svg"
import PrintProvider, { Print, NoPrint } from 'react-easy-print';
import Collapsible from 'react-collapsible'

export default class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.barRef = createRef();
    this.headerRef = createRef();
    this.state = {collapsed: "true"};
    this.collapsedClass = "nav--collapsed";
    this.openClass = "nav--open";
    this.lsKey = "navCollapsed";
    this.user = JSON.parse(localStorage.getItem("914ebf336ee44d9deba3f19de35417ace22dc318b8a1024bcd9fe9b4f083a9ae"));
    this.collapse = this.collapse.bind(this);
    this.navBarProfile = this.navBarProfile.bind(this);
    this.navBarHome = this.navBarHome.bind(this);
    this.navBarAgendas = this.navBarAgendas.bind(this);
    this.navBarRecord = this.navBarRecord.bind(this);
    this.navBarYearPlannings = this.navBarYearPlannings.bind(this);
    this.navBarUsers = this.navBarUsers.bind(this)
    this.headerShort = "header-short";
    this.state = {
      page: "YearPlannings",
    };
    this.currentPage = window.location.href.split("/").pop();

    this.handleLogout = this.handleLogout.bind(this);
  }

  getLogo () {
    axios.post(
      "/user/get_klein_logo",
      {
        instantie_id: this.user.instantie_id,
      },
      { withCredentials: true }
    )
    .then(res => {
      this.setState({
        klein_logo: res.data
      });
    }).catch((error) => {
      // Log it. A missing logo is not serious
      console.log(error);
    });
  }

  componentDidMount() {
    this.getLogo();
  }

  collapse() {
    this.barRef.current.classList.toggle(this.collapsedClass);
    this.barRef.current.classList.toggle(this.openClass);
    this.headerRef.current.classList.toggle(this.headerShort);
    sessionStorage.setItem(
      this.lsKey,
      this.barRef.current.classList.contains(this.collapsedClass)
    );
  }

  navBarProfile() {
    let homeLink = document.getElementById("profile");
    let yPosition =
      homeLink.offsetTop - homeLink.scrollTop + homeLink.clientTop;
    document.getElementById("nav-line").style.top = yPosition + "px";

    sessionStorage.setItem("Profile", "Profile");
    this.setState({page: "Profile"});
  }
  navBarHome() {
    let homeLink = document.getElementById("home");
    let yPosition =
      homeLink.offsetTop - homeLink.scrollTop + homeLink.clientTop;
    document.getElementById("nav-line").style.top = yPosition + "px";

    sessionStorage.setItem("Home", "Home");
    this.setState({page: "Home"});
  }
  navBarYearPlannings() {
    let homeLink = document.getElementById('year-planning');
    let yPosition = (homeLink.offsetTop - homeLink.scrollTop + homeLink.clientTop);
    document.getElementById('nav-line').style.top = yPosition + "px";

    sessionStorage.setItem("YearPlannings", "YearPlannings");
    this.setState({ page: "YearPlannings" });
  }
  navBarAgendas() {
    let homeLink = document.getElementById('agendas');
    let yPosition = (homeLink.offsetTop - homeLink.scrollTop + homeLink.clientTop);
    document.getElementById('nav-line').style.top = yPosition + "px";

    sessionStorage.setItem("Agendas", "Agendas");
    this.setState({ page: "Agendas" });
  }

  navBarRecord() {
    let homeLink = document.getElementById("record");
    let yPosition =
      homeLink.offsetTop - homeLink.scrollTop + homeLink.clientTop;
    document.getElementById("nav-line").style.top = yPosition + "px";

    sessionStorage.setItem("Record", "Record");
    this.setState({page: "Record"});
  }
  navBarUsers() {
    let homeLink = document.getElementById("users");
    let yPosition = (homeLink.offsetTop - homeLink.scrollTop + homeLink.clientTop);
    document.getElementById('nav-line').style.top = yPosition + "px";

    sessionStorage.setItem("Users", "Users");
    this.setState({ page: "Users" });
    this.setState({openAdminCollapse: 'closed'})
  }
  navBarAdminFeatures(e){
    this.setState({page: e})

  }
  handleLogout() {
    localStorage.clear();
    window.location.href = "/";
  };

  render() {
    if (sessionStorage.getItem(this.lsKey) === "true") {
      this.nav = "nav nav--collapsed";
      this.header = "header";
    } else {
      this.nav = "nav nav--open";
      this.header = "header header-short";
    }
    if (sessionStorage.getItem(this.state.page) != null) {
      this.state.page = sessionStorage.getItem(this.state.page);
    }

   const adminFeatures = ['Gebruikers', 'Organen', 'Verantwoordelijken']
    return (
      
      <NoPrint >
      <div className="page-container" >
        <nav className={this.nav} ref={this.barRef}>
          <ul>
            <Link className="nav-profile" to="#">
              <img src={this.state.klein_logo} alt="Home" />
              <span>{ this.user.naam }</span>
            </Link>

            <div className="nav-links">
              <div id={"nav-line"}></div>

                <Link id="year-planning" to="/" onClick={this.navBarYearPlannings} className={`navLink ${this.state.page == 'YearPlannings' ? 'active' : ''}`}>
                  <div>
                    <ReactJaarPlanningIcon />
                  </div>
                  <span>Jaar-planning</span>
                </Link>

                <Link id="agendas" to="/Agendas" onClick={this.navBarAgendas} className={`navLink ${this.state.page == 'Agendas' ? 'active' : ''}`}>
                  <div>
                    <ReactAgendaIcon />
                  </div>
                  <span>Agenda</span>
                </Link>
              {
                !!(this.user && this.user.is_admin) &&

                <Collapsible is-closed="closed" class="nav-collapsible" trigger={
                  <Link id="users" to="/Gebruikers" onClick={this.navBarUsers} >
                    <div>
                      <ReactUsersIcon />
                    </div>
                    <span>Admin</span>
                  </Link>
                }>
                  <ul>
                    {adminFeatures.map((feature, index) => (
                      <li key={index}>
                        <Link id={feature} to={"/" + feature} onClick={(event) => this.navBarAdminFeatures(event.target.id)} className={`featureLink ${this.state.page == feature ? 'sub-active' : ''}`}>
                          {feature}
                        </Link>
                      </li>
                    ))}</ul>
                </Collapsible>}
                {/*<Link id="profile" to="/Profile" onClick={this.navBarProfile} className={`navLink ${this.state.page == 'Profile' ? 'active' : ''}`}>*/}
                {/*  <div>*/}
                {/*    <ReactStukkenIcon />*/}
                {/*  </div>*/}
                {/*  <span>Account</span>*/}
                {/*</Link>*/}

                {/*<Link id="record" to="/Record" onClick={this.navBarRecord} className={`navLink ${this.state.page == 'Record' ? 'active' : ''}`}>*/}
                {/*  <div>*/}
                {/*    <ReactVergaderIcon />*/}
                {/*  </div>*/}
                {/*  <span>Record</span>*/}
                {/*</Link>*/}

                <Link to="#" onClick={this.handleLogout}>
                  <div>
                    <ReactSignOutIcon />
                  </div>
                  <span>Uitloggen</span>
                </Link>
            </div>

            <img
              className="nav-logo"
              src={floxxLogo}
              alt="FLOXX meetings logo"
            />
          </ul>
        </nav>
      </div>
      </NoPrint> 
     
    );
  }
}
