import React, {Component} from "react";
import Header from "./Header";

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.handleSuccessfulAuth = this.handleSuccessfulAuth.bind(this);
  }

  handleSuccessfulAuth(data) {
    this.props.handleLogin(data);
    this.props.history.push("/Profile");
  }

  render() {
    return (
      <div>
        <Header titlePage="Home" backOption="0" />
      </div>
    );
  }
}
