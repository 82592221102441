// header at the top of the page
import "./../../index.css";
import React from "react";
import {ReactComponent as ReactBackIcon} from "./recources/icons/Pagina_terug_Icoon.svg";
import { NoPrint } from 'react-easy-print';

export default class Header extends React.Component {
  visibility;
  printVisibility;
  formatVisibility;
  helpVisibility

  goBack() {
    window.history.back();
  }

  setVisibility() {
    this.visibility = "hidden";
    if (this.props.backOption === '1') {
      this.visibility = "visable";
    }
  }

  setPrintVisibility() {
    this.printVisibility = "hidden";
    if (this.props.printOption === '1') {
      this.printVisibility = "visable";
    }
  }
  setFormatVisibility() {
    this.formatVisibility = "hidden";
    if (this.props.formatOption === '1') {
      this.formatVisibility = "visable";
    }
  }
  setHelpVisibility() {
    this.helpVisibility = "hidden";
    if (this.props.helpOption === '1') {
      this.helpVisibility = "visable";
    }
  }
  render() {
    this.setVisibility();
    this.setPrintVisibility();
    this.setFormatVisibility();
    this.setHelpVisibility();


    return (  
      <NoPrint>
      <div className="header">

        <div className='leftChildHeader'>
          <div className={this.visibility}> 
            <div className='button-container'> 
              <button className="button-content" onClick={this.goBack}>
                <ReactBackIcon  className='button-icon'/>
                <span className="button-text">Terug</span>
              </button>
            </div>
          </div>
        </div>

        <div className='middleChildHeader'>
          <div className="title-container">
            <div className="title-text">
              {this.props.titlePage} 
            </div>
          </div>
        </div>

        <div className='rightChildHeader'>
          <div className='positionButtons'>
            <div className="printButton">
              {this.props.testPrint}
            </div>
            
          </div>
          <div className='positionFormatButton'>
            <div className={this.formatVisibility}>
            <a href="/#/format" className="formatbutton">Format</a>
            </div>
          </div>
          <div className='positionHelpButton'>
            <div className={this.helpVisibility}>
            <a href="" className="helpbutton">Help</a>

            </div>
          </div>
        </div>

      </div>
      </NoPrint>
    );
  }
}
