import React, {Component} from "react";
import {EditText} from 'react-edit-text';
import Dropdown from 'react-dropdown';
import Header from "../Header";
import {ReactComponent as ReactCloseIcon} from "../recources/icons/close-icon.svg"
import {ReactComponent as ReactArrowIcon} from "../recources/icons/arrow-icon.svg"
import {ReactComponent as VerwijderSelectieIcon} from "../recources/icons/delete-selectie-icon.svg"

import {ReactComponent as EyeIcon} from "../recources/icons/eye-icon.svg"
import {ReactComponent as InvulIcon} from "../recources/icons/invul-icon.svg"
import {ReactComponent as VolgordeIcon} from "../recources/icons/volgorde-icon.svg"
import {ReactComponent as VerwijderIcon} from "../recources/icons/delete-icon.svg"
import dotIcon from "../recources/icons/dot-icon.svg"
import checkIcon from "../recources/icons/check-icon.svg"
import crossIcon from "../recources/icons/cross-icon.svg"
import VBIconRed from "../recources/icons/VB-icon-red.svg"
import VBIconGreen from "../recources/icons/VB-icon-green.svg"
import VBIconBlue from "../recources/icons/VB-icon-blue.svg"
import axios from "axios";
import classNames from "classnames";

import {ReactComponent as PrintIcon} from "../recources/icons/Pagina-afdrukken-Icoon.svg"
import { Print, NoPrint } from 'react-easy-print';
import moment from "moment";

class Home extends Component {

    getAllYearPlanningData() {

        function groupBy(key) {
            return function group(array) {
                return array.reduce((acc, obj) => {
                    const property = obj[key];
                    acc[property] = acc[property] || [];
                    acc[property].push(obj);
                    return acc;
                }, {});
            };
        }

        const id = this.props.match.params.id;

        axios.post(
            "/jaar_planning/get_jaar_planning_inhoud",
            {
                id: id,
            },
            {withCredentials: true}
        ).then((response) => {
            const groupByyear = groupBy("onderdeel_id");
            const yearPlannings = typeof response.data === 'string' ? [] : response.data;
            let toSetStateArray = [];
            toSetStateArray.yearPlanningData = groupByyear(yearPlannings);

            Object.keys(groupByyear(yearPlannings)).map((onderdeelValue, onderdeelIndex) => {
                let name = "onderdeelTextName" + onderdeelValue;
                toSetStateArray[name] = groupByyear(yearPlannings)[onderdeelValue][0].onderdeel_naam;
                groupByyear(yearPlannings)[onderdeelValue].map(onderwerpValue => {
                    const variableValue = "onderwerpTextName" + onderwerpValue.id;
                    const toelichtingValue = "onderwerpToelichtingName" + onderwerpValue.id;
                    toSetStateArray[toelichtingValue] = onderwerpValue.toelichting;
                    toSetStateArray[variableValue] = onderwerpValue.naam;
                })
            })

            if (response.data === '') {
                this.setState({
                    mode: 1
                })
            }

            this.setState(toSetStateArray)
        });
    }

    getAllVerandwoordelijkeOptions() {
        let verandwoordelijkeOptionsArray = [];
        axios.post("/rollen/get_rollen", 
        {
            instantie_id: this.state.user.instantie_id,
        },
        {
            withCredentials: true,
        }).then((response) => {
            // verandwoordelijkeOptionsArray.push({value: "", label: "Selecteer verantwoordelijke"});
            response.data.map(value => {
                verandwoordelijkeOptionsArray.push({value: value.id, label: value.rol});
            })
            verandwoordelijkeOptionsArray.sort();
            this.setState({
                verandwoordelijkeOptions: verandwoordelijkeOptionsArray
            })
        });
    }

    constructor(props) {
        super(props);
        this.state = {
            monthStatusIcons: [null, dotIcon, checkIcon, crossIcon, VBIconBlue, VBIconGreen, VBIconRed],
            index: 0,
            yearPlanningData: [],
            verandwoordelijkeOptions: [],
            newOnderdeelValue: "",
            errorMessageToggle: false,
            errorMessage: "",
            mode: 0,
            rowsToRemove: [],
            tableOptions: [ dotIcon, checkIcon, crossIcon, VBIconBlue, VBIconGreen, VBIconRed],
            readonly: false,
            user: JSON.parse(localStorage.getItem("914ebf336ee44d9deba3f19de35417ace22dc318b8a1024bcd9fe9b4f083a9ae")),
        }

        this.getAllYearPlanningData();
        this.getAllVerandwoordelijkeOptions();

        this.titleBuilder = this.titleBuilder.bind(this);

        this.tableRows = this.tableRows.bind(this);
        this.onClickMonth = this.onClickMonth.bind(this);
        this.getAllYearPlanningData = this.getAllYearPlanningData.bind(this);
        this.getAllVerandwoordelijkeOptions = this.getAllVerandwoordelijkeOptions.bind(this);
        this.handleRemoveYearPlanningAccepted = this.handleRemoveYearPlanningAccepted.bind(this);
        this.changeMode = this.changeMode.bind(this);
    }

    componentDidMount() {
        if (this.props.location && this.props.location.search && this.props.location.search === "?readonly=true") {
            this.setState({ readonly: true});
        }
    }

    changeMode(mode) {
        this.setState({
            mode: mode,
        })
    }

    selectToRemove(id) {
        if (this.state.mode === 3) {
            if (!this.state.rowsToRemove.includes(id)) {
                const newArray = this.state.rowsToRemove.concat(id);
                this.setState({
                    rowsToRemove: newArray
                })
            } else {
                let newArray = this.state.rowsToRemove
                const index = newArray.indexOf(id);
                newArray.splice(index, 1)
                this.setState({
                    rowsToRemove: newArray
                })
            }
        }
    }

    onClickMonth(event, id, month) {
        if (event.value) {
            let newValue = event.value;

            newValue === 7 ? newValue = 0 : null
            let formattedArray = {
                id: id,
                column: month,
                value: newValue,
            }
            axios.post(
                "/jaar_planning/update_jaar_onderwerp",
                {
                    jaar_onderwerp: formattedArray,
                },
                {withCredentials: true}
            ).then(this.getAllYearPlanningData);
        }
    }

    openRemoveModal() {
        let modal = document.getElementById("remove-confirm-modal");
        modal.style.display = "block";
        modal.style.opacity = 1;
    }

    closeModals() {
        let removeConfirmModal = document.getElementById("remove-confirm-modal");
        removeConfirmModal.style.display = "none";
        removeConfirmModal.style.opacity = 0;
    }

    handleRemoveYearPlanningAccepted() {
        let yearPlanningIdToRemove = this.state.rowsToRemove

        axios.post(
            "/jaar_planning/remove_jaar_onderwerp",
            {
                id: yearPlanningIdToRemove,
            },
            {withCredentials: true}
        ).then((response) => {
            this.getAllYearPlanningData();
            this.closeModals();
        })
    }

    handleOnSaveOnderdeel(data, id) {
        if (data.value.length >= 45) {
            this.setError("Gebruik maximaal 45 karakters voor een onderdeel. Wijziging is niet opgeslagen!")
        } else {
            if (!data.value.replace(/\s/g, '').length) {
                data.value = null;
            }
            let formattedArray = {
                id: id,
                column: data.name,
                value: data.value,
            }

            axios.post(
                "/jaar_planning/update_jaar_onderdeel",
                {
                    jaar_onderdeel: formattedArray,
                },
                {withCredentials: true}
            ).then(() => {
                this.getAllYearPlanningData()
            });
        }
    }

    handleOnSaveOnderwerp(data, id) {
        if (data.name === "naam" && data.value.length >= 80) {
            this.setError("Gebruik maximaal 80 karakters voor een onderwerp. Wijziging is niet opgeslagen!")
        } else if (data.name === "toelichting" && data.value.length >= 200) {
            this.setError("Gebruik maximaal 200 karakters voor een toelichting. Wijziging is niet opgeslagen!")
        } else {
            if (!data.value.replace(/\s/g, '').length) {
                data.value = null;
            }
            let formattedArray = {
                id: id,
                column: data.name,
                value: data.value,
            }

            axios.post(
                "/jaar_planning/update_jaar_onderwerp",
                {
                    jaar_onderwerp: formattedArray,
                },
                {withCredentials: true}
            ).then(() => {
                this.getAllYearPlanningData()
            });
        }
    }

    handleOnSaveNewOnderwerp(data, onderdeelId) {
        if (data.value || data.value === null) {
            if (data.value !== null && data.value.length >= 80) {
                this.setError("Gebruik maximaal 80 karakters voor een onderwerp. Wijziging is niet opgeslagen!")
            } else {
                let indexOnderwerp = 0
                if (this.state.yearPlanningData[onderdeelId]) {
                    indexOnderwerp = this.state.yearPlanningData[onderdeelId].length
                }
                const formattedArray = {
                    onderdeel_id: onderdeelId,
                    index_onderwerp: indexOnderwerp,
                    naam: data.value,
                    januari: 0,
                    februari: 0,
                    maart: 0,
                    april: 0,
                    mei: 0,
                    juni: 0,
                    juli: 0,
                    augustus: 0,
                    september: 0,
                    oktober: 0,
                    november: 0,
                    december: 0,
                }

                axios.post(
                    "/jaar_planning/add_jaar_onderwerp",
                    {
                        jaar_onderwerp: formattedArray,
                    },
                    {withCredentials: true}
                ).then((response) => {
                    const variableValue = "onderwerpTextName" + onderdeelId;
                    this.setState({[variableValue]: ""});
                    this.getAllYearPlanningData();
                });
            }
        }
    }

    setError(errorMessage) {
        this.setState({
            errorMessageToggle: true,
            errorMessage: errorMessage
        });
        const errorMessageToggleFalse = () => {
            this.setState({errorMessageToggle: false});
        }
        setTimeout(errorMessageToggleFalse, 5000);
    }

    handleOnSaveNewOnderdeel(data) {
        if (data.value) {
            if (data.value.length >= 45) {
                this.setError("Gebruik maximaal 45 karakters voor een onderdeel. Wijziging is niet opgeslagen!")
            } else {
                this.setState({errorMessageToggle: false});
                let indexOnderdeel = 0
                if (Object.values(this.state.yearPlanningData).length !== 0) {
                    indexOnderdeel = Object.values(this.state.yearPlanningData)[Object.values(this.state.yearPlanningData).length - 1][0].index_onderdeel + 1
                }
                const formattedArray = {
                    jaar_planning_id: this.props.match.params.id,
                    naam: data.value,
                    index_onderdeel: indexOnderdeel
                }

                axios.post(
                    "/jaar_planning/add_jaar_onderdeel",
                    {
                        jaar_onderdeel: formattedArray,
                    },
                    {withCredentials: true}
                ).then((response) => {
                    this.handleOnSaveNewOnderwerp({value: null}, response.data["new_task_id"]);
                    this.setState({newOnderdeelValue: ""});
                    this.getAllYearPlanningData();
                });
            }
        }
    }

    handleOnChangeVerandwoordelijke(data, id) {
        const element = document.getElementsByClassName("verandwoordelijke-" + id)[0];
        const dropdownElement = element.getElementsByClassName("Dropdown-placeholder")[0]

        if (data.value === "") {
            data.value = undefined;
            dropdownElement.classList.remove("is-selected");
        } else {
            dropdownElement.classList.add("is-selected");
        }

        let formattedArray = {
            id: id,
            column: "verantwoordelijke",
            value: data.value,
        }

        axios.post(
            "/jaar_planning/update_jaar_onderwerp",
            {
                jaar_onderwerp: formattedArray,
            },
            {withCredentials: true}
        ).then(this.getAllYearPlanningData);
    }

    // if onderwerpId is set to null, the onderdeel is changed in index insead of the onderwerp
    changeIndex(state, onderdeelId, onderwerpId) {
        let yearPlanningData = this.state.yearPlanningData

        if (onderwerpId) {
            let oldIndex = yearPlanningData[onderdeelId].findIndex(x => x.id === onderwerpId)
            let newIndex = oldIndex + state

            if (newIndex !== -1) {
                var element = yearPlanningData[onderdeelId][oldIndex];
                yearPlanningData[onderdeelId].splice(oldIndex, 1);
                yearPlanningData[onderdeelId].splice(newIndex, 0, element);

                let changedIndices = {
                    ids: [],
                    indices: [],
                    onderdeel_id: onderdeelId,
                }

                yearPlanningData[onderdeelId].forEach(function (onderwerp, index) {
                    changedIndices.ids.push(onderwerp.id)
                    changedIndices.indices.push(index)
                })

                axios.post(
                    "/jaar_planning/update_jaar_onderwerp_indices",
                    {
                        jaar_order: changedIndices,
                    },
                    {withCredentials: true}
                )

                this.setState({
                    yearPlanningData: yearPlanningData
                })
            }
        } else {
            let yearPlanningDataKeys = Object.keys(yearPlanningData);
            let newYearPlanningArray = {}
            let oldIndex = Object.keys(yearPlanningData).indexOf(onderdeelId)
            let newIndex = oldIndex + state
            if (newIndex !== -1) {
                let element = yearPlanningDataKeys[oldIndex];
                yearPlanningDataKeys.splice(oldIndex, 1);
                yearPlanningDataKeys.splice(newIndex, 0, element);

                yearPlanningDataKeys.forEach(function (onderdeel, index) {
                    newYearPlanningArray[onderdeel] = yearPlanningData[onderdeel]
                    const jaar_onderdeel = {
                        id: onderdeel,
                        column: "index_onderdeel",
                        value: index,
                    }

                    axios.post(
                        "/jaar_planning/update_jaar_onderdeel",
                        {
                            jaar_onderdeel: jaar_onderdeel,
                        },
                        {withCredentials: true}
                    )
                })

                this.setState({
                    yearPlanningData: newYearPlanningArray
                })
            }
        }
    }

    tableRows() {
        const yearPlanningData = this.state.yearPlanningData;

        const onChangeNewOnderdeelValue = (newText) => {
            this.setState({newOnderdeelValue: newText});
        }

        const newOnderdeelEditTextValue = (onderdeelId) => {
            const variableValue = "onderwerpTextName" + onderdeelId;
            let state = this.state;
            return state[variableValue];
        }

        const setNewOnderdeelEditTextValue = (newText, onderdeelId) => {
            const variableValue = "onderwerpTextName" + onderdeelId;
            this.setState({[variableValue]: newText});
        }

        const onderdeelEditTextValue = (onderwerpId) => {
            const variableValue = "onderdeelTextName" + onderwerpId;
            let state = this.state;
            return state[variableValue];
        }

        const setOnderdeelEditTextValue = (newText, onderwerpId) => {
            const variableValue = "onderdeelTextName" + onderwerpId;
            this.setState({[variableValue]: newText});
        }

        const onderwerpEditTextValue = (onderwerpId) => {
            const variableValue = "onderwerpTextName" + onderwerpId;
            let state = this.state;
            return state[variableValue];
        }

        const setOnderwerpEditTextValue = (newText, onderwerpId) => {
            const variableValue = "onderwerpTextName" + onderwerpId;
            this.setState({[variableValue]: newText});
        }

        const onderwerpEditToelichtingValue = (onderwerpId) => {
            const variableValue = "onderwerpToelichtingName" + onderwerpId;
            let state = this.state;
            return state[variableValue];
        }

        const setOnderwerpEditToelichtingValue = (newText, onderwerpId) => {
            const variableValue = "onderwerpToelichtingName" + onderwerpId;
            this.setState({[variableValue]: newText});
        }

        const onderwerpRowClasses = (id, length, index) => {
            let classes = {
                'remove-hover': false,
                'selected-to-remove': false,
                'border-bottom' : false,
            };

            if (index + 1 === length && this.state.mode !== 1) {
                classes['border-bottom'] = true;
            }
            
            if (this.state.mode === 3) {
                classes['remove-hover'] = true;
                classes['selected-to-remove'] = this.state.rowsToRemove.includes(id);

            } else {
                classes['remove-hover'] = false;
                classes['selected-to-remove'] = false;
            }

            return classes;
        }

        const activeMonth = (month, newOnderwerpRow) => {
            let classes = {
                'active-month': false,
                'month' : false
            }

            if (moment().format('MMMM') === month) {
                classes['active-month'] = true
            }

            if (this.state.mode === 1 && !newOnderwerpRow) {
                classes['month'] =  true
            }

            return classes
        }

        const firstColClasses = () => {
            let classes = {
                'first-col': true,
                'not-clickable': false
            }

            if (this.state.mode === 3) {
                classes['not-clickable'] =  true
            }

            return classes
        }

        const monthDropdownOptions = [
            {value: 0, label: <img src={dotIcon} height='0px' width='0px' alt='' />},
            {value: 1, label: <img src={dotIcon} alt='' />},
            {value: 2, label: <img src={checkIcon} alt='' />},
            {value: 3, label: <img src={crossIcon} alt='' />},
            {value: 4, label: <img src={VBIconBlue} alt='' />},
            {value: 5, label: <img src={VBIconGreen} alt='' />},
            {value: 6, label: <img src={VBIconRed} alt='' />},
            {value: 7, label: <button>Verwijder selectie<VerwijderSelectieIcon/></button>}
        ];

        function onLoadMonth(event) {
            const dropdownElement = event.target.parentElement.parentElement;
            const tableCellElement = event.target.parentElement.parentElement.parentElement.parentElement
            if (dropdownElement.classList.contains('Dropdown-control')) {
                tableCellElement.style.padding = '0px'
                dropdownElement.style.height = tableCellElement.offsetHeight + "px"
            }
        }

        return (
            <tbody>
            {Object.keys(yearPlanningData).map((onderdeelValue, onderdeelIndex) => {
                return (
                    <React.Fragment key={onderdeelIndex}>
                        {yearPlanningData[onderdeelValue].map((value, index) => {
                            function findVerandwoodelijkeLabel(verantwoordelijke) {
                                return verantwoordelijke.value === value.verantwoordelijke;
                            }

                            return (
                                <tr key={value.id} className={classNames(onderwerpRowClasses(value.id, yearPlanningData[onderdeelValue].length, index))} onClick={() => this.selectToRemove(value.id)}>
                                    {
                                        index ? null :
                                            <td className={classNames(firstColClasses())}
                                                rowSpan={this.state.mode !== 1 ?
                                                    yearPlanningData[onderdeelValue].length + 0 :
                                                    yearPlanningData[onderdeelValue].length + 1}>
                                                { this.state.mode === 2 ?
                                                    <div className="position-selector">
                                                        <ReactArrowIcon onClick={() => this.changeIndex(-1, value.onderdeel_id, null)} />
                                                        <ReactArrowIcon onClick={() => this.changeIndex(1, value.onderdeel_id, null)}/>
                                                    </div>
                                                    : null }
                                                {onderdeelIndex + 1}.&nbsp;
                                                { this.state.mode === 1 ?
                                                    <EditText onSave={event => this.handleOnSaveOnderdeel(event, onderdeelValue)}
                                                    name="naam" defaultValue={value.onderdeel_naam}
                                                    placeholder="Wijzig onderdeel..."
                                                    onChange={event => setOnderdeelEditTextValue(event, value.onderdeel_id)}
                                                    value={onderdeelEditTextValue(value.onderdeel_id)}/>
                                                : onderdeelEditTextValue(value.onderdeel_id) }
                                            </td>
                                            
                                    }
                                    <td className="second-col">
                                        { this.state.mode === 2 ?
                                            <div className="position-selector">
                                                <ReactArrowIcon onClick={() => this.changeIndex(-1, value.onderdeel_id, value.id)} />
                                                <ReactArrowIcon onClick={() => this.changeIndex(1, value.onderdeel_id, value.id)}/>
                                            </div>
                                            : null }

                                        { this.state.mode === 1 ?
                                            <EditText onSave={event => this.handleOnSaveOnderwerp(event, value.id)}
                                                  name="naam" onChange={event => setOnderwerpEditTextValue(event, value.id)}
                                                  value={onderwerpEditTextValue(value.id)} placeholder="Wijzig onderwerp..."/>
                                            : onderwerpEditTextValue(value.id) }
                                    </td>

                                    {
                                        moment.months().map((month, index) => {
                                            return (
                                                <td key={index} id={value[month]} className={classNames(activeMonth(month, 0))}
                                                    onLoad={event => onLoadMonth(event)}>
                                                    {this.state.mode === 1 ?
                                                        <Dropdown className={"month-dropdown"}
                                                                  options={monthDropdownOptions}
                                                                  value={monthDropdownOptions[value[month]]}
                                                                  onChange={event => this.onClickMonth(event, value.id, month)}
                                                                  placeholder=""/>
                                                        : monthDropdownOptions[value[month]].label
                                                    }
                                                </td>
                                            )
                                        })
                                    }

                                    <td className="second-last">
                                        { this.state.mode === 1 ?
                                            <Dropdown className={"verandwoordelijke-" + value.id}
                                                      options={this.state.verandwoordelijkeOptions}
                                                      onChange={event => this.handleOnChangeVerandwoordelijke(event, value.id)}
                                                      value={this.state.verandwoordelijkeOptions.find(findVerandwoodelijkeLabel)}
                                                      placeholder="Selecteer verantwoordelijke"/>
                                            :
                                            <div>
                                                {this.state.verandwoordelijkeOptions.find(findVerandwoodelijkeLabel) ?
                                                    this.state.verandwoordelijkeOptions.find(findVerandwoodelijkeLabel).label
                                                    : null }
                                        </div>}
                                    </td>
                                    <td className="last-col">
                                        { this.state.mode === 1 ?
                                        <EditText onSave={event => this.handleOnSaveOnderwerp(event, value.id)}
                                                  name="toelichting" placeholder="nieuwe toelichting..."
                                                  onChange={event => setOnderwerpEditToelichtingValue(event, value.id)}
                                                  value={onderwerpEditToelichtingValue(value.id)}/>
                                            : onderwerpEditToelichtingValue(value.id) }
                                    </td>
                                </tr>
                            )
                        })}
                        { this.state.mode === 1 ?
                            <tr className="border-bottom">
                                <td className="second-col">
                                    <EditText className="new-onderwerp" onChange={event => setNewOnderdeelEditTextValue(event, onderdeelValue)}
                                          onSave={event => this.handleOnSaveNewOnderwerp(event, onderdeelValue)}
                                          value={newOnderdeelEditTextValue(onderdeelValue)} placeholder="Nieuw onderwerp..."
                                          name="new-onderwerp"/>
                                </td>
                                {
                                    moment.months().map((month, index) => {
                                        return (
                                            <td key={index} className={classNames(activeMonth(month, 1))}></td>
                                        )
                                    })
                                }
                                <td className="second-last"></td>
                                <td className="last-col"></td>
                            </tr>
                            : null}

                    </React.Fragment>
                )
            })}
            { this.state.mode === 1 ?
                <tr className="border-bottom">
                    <td className="first-col">
                        <EditText className="new-onderwerp"
                                  onSave={event => this.handleOnSaveNewOnderdeel(event)}
                                  value={this.state.newOnderdeelValue}
                                  onChange={onChangeNewOnderdeelValue}
                                  placeholder="Nieuw onderdeel..."
                                  name="new-onderdeel"/>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="second-last"></td>
                    <td className="last-col"></td>
                </tr>
                : null}
            <tr colSpan="40" className="tablebottom"/>
            </tbody>
        )
    }


    titleBuilder(){
        const currentYearplanning = JSON.parse(localStorage.getItem('currentYearplanning'))
        const type =  currentYearplanning.type_planning;
        const instantie = currentYearplanning.instantie;
        const year = currentYearplanning.year;
        const orgaan = currentYearplanning.orgaan;

        return type + ' ' + orgaan + ' ' + instantie + ' ' + year;
    }

    handleScrollEvent() {
        const changeModeBtn = document.querySelector('.change-mode-bar')
        window.addEventListener('scroll', () => {
                const scrollDistance = window.scrollY;

                if (scrollDistance === 0) {
                    changeModeBtn.classList.remove('hide-btn-styling')
                } else {
                    changeModeBtn.classList.add('hide-btn-styling')
                }
            }
        )
    }

    modeSelector() {
        const changeModeItemClasses = (mode) => {
            let classes = {
                'change-mode-item': true,
                'active': false,
            };

            if (this.state.mode === mode) {
                classes = {
                    'change-mode-item': true,
                    'active': true,
                };
            }

            return classes
        }

        const orgaan = JSON.parse(localStorage.getItem('currentYearplanning')).orgaan
        const userOrgaan = this.state.loggedInUser
        let loggedInUser = JSON.parse(localStorage.getItem("914ebf336ee44d9deba3f19de35417ace22dc318b8a1024bcd9fe9b4f083a9ae"))
        let rechten = loggedInUser.rechten
        let rollen = loggedInUser.rollen
        let userRollen = []
        rollen.forEach((rol, i) => {
            userRollen.push({ 'orgaan': rol, 'rechten': rechten[i] })
        })
        let userRechten = userRollen.filter(user => user.orgaan === orgaan)

        return (
            <div className="change-mode-bar">
                <div className="change-mode-active-mode">active modus: </div>
                <div className={classNames(changeModeItemClasses(0))} onClick={() => this.changeMode(0)}><EyeIcon /> Bekijken</div>
                <div style={{ visibility: !this.state.readonly && (userRechten[0].rechten === 1 || userRechten[0].rechten === 2) ? 'visible' : 'hidden' }} className={classNames(changeModeItemClasses(1))} onClick={() => this.changeMode(1)}><InvulIcon /> Invullen</div>
                <div style={{ visibility: !this.state.readonly && (userRechten[0].rechten === 1 || userRechten[0].rechten === 2) ? 'visible' : 'hidden' }} className={classNames(changeModeItemClasses(2))} onClick={() => this.changeMode(2)}><VolgordeIcon /> Volgorde bepalen</div>
                <div style={{ visibility: !this.state.readonly && (userRechten[0].rechten === 1 || userRechten[0].rechten === 2) ? 'visible' : 'hidden' }} className={classNames(changeModeItemClasses(3))} onClick={() => this.changeMode(3)}><VerwijderIcon /> Verwijderen</div>
                {this.state.mode === 3 ?
                    <div className="change-mode-item" onClick={() => this.openRemoveModal()}>Selectie verwijderen</div>
                    : null}
                    </div>
        )
                }

    render() {

        const onClickPrintBtn = () => {
            window.scrollTo(0, 0);
            window.print();
        }

        const printButton =
            <button className='roundButton' onClick={() => onClickPrintBtn()}>
                <PrintIcon className='roundIcon'/>
            </button> ;

        return(
            <NoPrint>
                <Header titlePage={<this.titleBuilder />} backOption="1" testPrint={printButton} > </Header>
                <Print printOnly name="foo">
                    <div style={{ fontWeight: '900', fontSize: '25px', textAlign: 'center', textDecoration: 'underline #BEBEBE'}}>
                        <this.titleBuilder />
                    </div>
                </Print>

                {this.modeSelector()}

                <div className="year-planning">
                    <div className={this.state.errorMessageToggle ? "error-message" : "error-message d-none"}>{this.state.errorMessage}</div>
                    <Print name="foo" >
                        <table className="table detail" onLoad={this.handleScrollEvent}>
                            <thead>
                                <tr>
                                    <th scope="col">Onderdeel</th>
                                    <th scope="col">Onderwerp</th>
                                    <th scope="col" className="month">Jan</th>
                                    <th scope="col" className="month">Feb</th>
                                    <th scope="col" className="month">Mrt</th>
                                    <th scope="col" className="month">Apr</th>
                                    <th scope="col" className="month">Mei</th>
                                    <th scope="col" className="month">Jun</th>
                                    <th scope="col" className="month">Jul</th>
                                    <th scope="col" className="month">Aug</th>
                                    <th scope="col" className="month">Sep</th>
                                    <th scope="col" className="month">Okt</th>
                                    <th scope="col" className="month">Nov</th>
                                    <th scope="col" className="month">Dec</th>
                                    <th scope="col">Verantwoordelijke</th>
                                    <th scope="col">Toelichting</th>
                                    
                                </tr>
                            </thead>
                            {this.tableRows()}
                        </table>
                    </Print>

                    <NoPrint >
                        <div id="remove-confirm-modal" className="modal">
                            <div className="modal-content">
                                <div className="modal-head">
                                    Weet u zeker dat u de geselecteerde items wilt verwijderen?
                                </div>
                                <ReactCloseIcon onClick={this.closeModals} className="close-icon"/>
                                <form action="">
                                    <div className="row">
                                        <div className="col-sm-6 m-auto d-flex">
                                            <input onClick={this.handleRemoveYearPlanningAccepted}
                                                className="button" role="button" type="button" value="Ja"/>
                                        </div>
                                        <div className="col-sm-6 m-auto d-flex">
                                            <input onClick={this.closeModals} className="button gray" role="button"
                                                type="button" value="Nee"/>
                                        </div>
                                    </div>
                                </form>
                            </div>
                         </div>
                    </NoPrint>
                </div>

            </NoPrint>
        );
    }
}

export default Home;