import React, { Component } from "react";
import axios from "axios";
import Dropdown from 'react-dropdown';
import { ReactComponent as ReactAddIcon } from "../recources/icons/add-icon.svg"
import { ReactComponent as ReactEditIcon } from "../recources/icons/edit-icon.svg"
import { ReactComponent as ReactCloseIcon } from "../recources/icons/close-icon.svg"
import { ReactComponent as ReactDuplicateIcon } from "../recources/icons/duplicate-icon.svg"
import { ReactComponent as ReactDeleteIcon } from "../recources/icons/delete-icon.svg"
import formArrow from "../recources/icons/formarrow.svg";
import {ReactComponent as ReactDropdownArrow} from "../recources/icons/formarrow.svg"
import Header from "../Header";

class Home extends Component {

    getAllYearPlanningsFormDatabase() {
        const instantie_id = this.state.loggedInUser.instantie_id;
        const rollen = this.state.loggedInUser.rollen;
        axios.post(
            "/jaar_planning/get_jaar_planningen",
            {
                instantie_id,
                rollen,
            },
            { withCredentials: true }
        ).then((response) => {
            this.setState({
                yearPlannings: typeof response.data === 'string' ? [] : response.data,
            });

            if (sessionStorage. getItem('datumDropdownValue') !== 'null' || sessionStorage.getItem('orgaanDropdownValue') !== 'null') {
                this.reloadFilters()
            }

        }).catch((error) => {
            console.log("task fetching error", error);
        });
    }

    constructor(props) {
        super(props);
        this.state = {
            modalTitle: "Nieuwe jaarplanning aanmaken",
            modalButton: "Maak aan",
            yearPlannings: [],
            yearPlanningTypes: [],
            yearPlanningOrganen: [],
            removeId: "",
            removeJaarplanningOrgaan: "",
            removeJaarplanningJaar: "",
            addFormId: null,
            addFormType: 1,
            addFormInstantie: "",
            addFormOrgaan: "",
            addFormYear: new Date().getFullYear(),
            errorMessageToggle: false,
            errorMessage: "",
            orgaanDropdownValue: null,
            jaarDropdownValue: null,
            filteredYearPlannings: [],
            mode: 0,
            loggedInUser: JSON.parse(localStorage.getItem("914ebf336ee44d9deba3f19de35417ace22dc318b8a1024bcd9fe9b4f083a9ae")),
        }
        this.jaren = [];

        this.getAllYearPlanningsFormDatabase();
        axios.get("/jaar_planning/get_types", {
            withCredentials: true,
        }).then((response) => {
            this.setState({
                yearPlanningTypes: response.data,
            });
        }).catch((error) => {
            console.log("task fetching error", error);
        });

        axios.post("/rollen/get_organen", 
        {
            instantie_id: this.state.loggedInUser.instantie_id
        },
        {
            withCredentials: true,
        }).then((response) => {
            this.setState({
                yearPlanningOrganen: response.data,
            });
        }).catch((error) => {
            console.log("task fetching error", error);
        });

        this.openAddEditModal = this.openAddEditModal.bind(this);
        this.handleRemoveYearPlanningAccepted = this.handleRemoveYearPlanningAccepted.bind(this);
        this.handleSubmitAddYearPlanning = this.handleSubmitAddYearPlanning.bind(this);
        this.handleOnChangeAddYearPlanningForm = this.handleOnChangeAddYearPlanningForm.bind(this);
        this.resetFormFields = this.resetFormFields.bind(this);
        this.hasLoggedInUserRechtOpJaarplanning = this.hasLoggedInUserRechtOpJaarplanning.bind(this);
    }

    laadJaren() {
        const eersteJaar = new Date().getFullYear();
        for (let i = 0; i < 5; i++) {
            this.jaren.push(eersteJaar + i)
            
        }
    }

    reloadFilters() {
        let jaarDropdownValue = sessionStorage.getItem('datumDropdownValue')
        let orgaanDropdownValue = sessionStorage.getItem('orgaanDropdownValue')
        jaarDropdownValue === 'null' ? jaarDropdownValue = null : null
        orgaanDropdownValue === 'null' ? orgaanDropdownValue = null : null
        let filteredYearPlannings = this.state.yearPlannings
        if (jaarDropdownValue && !orgaanDropdownValue) {
            filteredYearPlannings = this.state.yearPlannings.filter(yearPlanning => yearPlanning['jaar'] === parseInt(jaarDropdownValue))
        } else if (orgaanDropdownValue && !jaarDropdownValue) {
            filteredYearPlannings = this.state.yearPlannings.filter(yearPlanning => yearPlanning['orgaan'] === orgaanDropdownValue)
        } else if (orgaanDropdownValue && jaarDropdownValue) {
            filteredYearPlannings = this.state.yearPlannings.filter(function (result) {
                return result['jaar'] === parseInt(jaarDropdownValue) && result['orgaan'] === orgaanDropdownValue;
            } );
        }

        if (filteredYearPlannings.length === 0) {
            filteredYearPlannings = "no results"
        }

        this.setState({
            filteredYearPlannings: filteredYearPlannings
        })
    }
 
    //TODO make this so that it does not uses a local storage
    onClickYearplanning(clickedYearplanning, jaarPlanningInThePast) {
        const dataToSend = {
            orgaan: clickedYearplanning.orgaan,
            year: clickedYearplanning.jaar,
            instantie: clickedYearplanning.instantie,
            type_planning: clickedYearplanning.type_planning
        }
        localStorage.setItem('currentYearplanning', JSON.stringify(dataToSend))
        if (jaarPlanningInThePast) {
            this.props.history.push(`/yearPlanning/${clickedYearplanning.id}?readonly=true`);
        } else {
            this.props.history.push(`/yearPlanning/${clickedYearplanning.id}?readonly=false`);
        }
    }

    hasLoggedInUserRechtOpJaarplanning(orgaan, recht) {
        let hasRecht = false;

        if (this.state.loggedInUser.rollen) {
            const index = this.state.loggedInUser.rollen.findIndex(r => r === orgaan);
            if (index >= 0) {
                hasRecht = this.state.loggedInUser.rechten[index] === recht;
            } 
        }

        return hasRecht;
    }

    tableRows() {
        let yearPlannings = this.state.yearPlannings;
        if (this.state.filteredYearPlannings.length !== 0) {
            yearPlannings = this.state.filteredYearPlannings
        }

        if (this.state.filteredYearPlannings === "no results") {
            return (
                <tbody>
                    <td colSpan="7" className="px-4">Met de geselecteerde filters zijn er geen gevonden jaarplanningen</td>
                </tbody>
            )
        }
        return (
            <tbody>
                {yearPlannings.map(value => {
                    const jaarPlanningInThePast = value.jaar < new Date().getFullYear() ;
                    const isGebruikerBewerker = this.hasLoggedInUserRechtOpJaarplanning(value.orgaan, 2);
                    return (
                        <tr onClick={event => this.onClickYearplanning(value, jaarPlanningInThePast)} key={value.id} className={jaarPlanningInThePast ? "grayed" : ""}>
                            <td>{value.type_planning} </td>
                            <td>{value.orgaan}</td>
                            <td>{value.instantie}</td>
                            <td>{value.jaar}</td>
                            <td className="text-center" onClick={event => this.openDuplicateModal(value.id, event)}><ReactDuplicateIcon /></td>
                            { isGebruikerBewerker && !jaarPlanningInThePast ? 
                                <td className="text-center" onClick={event => this.openAddEditModal(value.id, event)}><ReactEditIcon /></td> :
                                <td></td> }
                            { isGebruikerBewerker ?
                                <td onClick={event => this.openRemoveModal(value, event)} className="delete-table-row" >{ }<ReactDeleteIcon/></td> :
                                <td></td> }
                        </tr>
                    )
                })}
            </tbody>
        )
    }

    formYearPlanningTypesSelectField() {
        const yearPlanningTypes = this.state.yearPlanningTypes;
        return (
            <select required value={this.state.addFormType} onChange={this.handleOnChangeAddYearPlanningForm}
               name="addFormType" id="exampleFormControlSelect1" disabled>
                <option value="">Type</option>
                {yearPlanningTypes.map(value => {
                    return (<option key={value.id} value={value.id}>{value.naam}</option>)
                })}
            </select>
        )
    }

    formYearPlanningOrganenSelectField() {
     let  loggedInUser = JSON.parse(localStorage.getItem("914ebf336ee44d9deba3f19de35417ace22dc318b8a1024bcd9fe9b4f083a9ae"))
        let rechten = loggedInUser.rechten
        let rollen = loggedInUser.rollen
        let rol_ids = loggedInUser.rol_ids

        let userRollen = []
        rollen.forEach((rol, i) => {
            userRollen.push({ 'orgaan': rol, 'rechten': rechten[i],  'rol_id' : rol_ids[i] })
        })

        let yearPlanningOrganen = []
        userRollen.forEach(rol => {
            if(rol.rechten === 2){
                yearPlanningOrganen.push( rol);
            }
        });

        return (
            <select required value={this.state.addFormOrgaan} onChange={this.handleOnChangeAddYearPlanningForm}
              name="addFormOrgaan" id="exampleFormControlSelect1" style={{ backgroundImage: `url(${formArrow})` }}>
                <option value="">Orgaan</option>
                {yearPlanningOrganen.map(value => {
                    return (<option key={value.rol_id} value={value.rol_id}>{value.orgaan}</option>)
                })}
            </select>
        )
    }

    componentDidMount() {

        this.laadJaren();

        let bewerkRechten = false;
        if (this.state.loggedInUser && this.state.loggedInUser.rechten && this.state.loggedInUser.rechten.filter(b => b === 2).length > 0) {
            // Gebruiker heeft bewerkrechten op tenminste 1 orgaan.
            bewerkRechten = true;
        }
        let user = this.state.loggedInUser;
        user.bewerkRechten = bewerkRechten;
        this.setState({ loggedInUser: user });
        
        let modal = document.getElementById("add-edit-year-planning-modal");

        window.onclick = function (event) {
            if (event.target == modal) {
                modal.style.display = "none";
                modal.style.opacity = 0;
            }
        }
    }

    openAddEditModal(id, event) {
        const body = document.querySelector('body')
        body.style.overflow = "hidden"
        event.stopPropagation();
        if (typeof id !== 'string') {
            this.setState({ modalTitle: "Nieuwe jaarplanning aanmaken" });
            this.setState({ modalButton: "Maak aan" });
            this.resetFormFields();
        } else {
            this.setState({ modalTitle: "Jaarplanning wijzigen" });
            this.setState({ modalButton: "wijzigen" });
            const yearPlanningToEdit = this.state.yearPlannings.find(x => x.id === id);
            const yearPlanningOrgaanToEdit = this.state.yearPlanningOrganen.find(x => x.rol === yearPlanningToEdit.orgaan).id;
            const yearPlanningTypeToEdit = this.state.yearPlanningTypes.find(x => x.naam === yearPlanningToEdit.type_planning).id;
            this.setState({
                addFormId: yearPlanningToEdit.id,
                addFormType: yearPlanningTypeToEdit,
                addFormInstantie: 1,
                addFormOrgaan: yearPlanningOrgaanToEdit,
                addFormYear: yearPlanningToEdit.jaar,
            })
        }

        let modal = document.getElementById("add-edit-year-planning-modal");
        modal.style.display = "block";
        modal.style.opacity = 1;
    }

    openRemoveModal(jaarplanning, event) {
        event.stopPropagation();
        this.setState({ 
            removeId: jaarplanning.id,
            removeJaarplanningOrgaan: jaarplanning.orgaan,
            removeJaarplanningJaar: jaarplanning.jaar
        })
        let modal = document.getElementById("remove-confirm-modal");
        modal.style.display = "block";
        modal.style.opacity = 1;
    }

    openDuplicateModal(id, event) {
        event.stopPropagation();
        this.resetFormFields();
        this.setState({ duplicateId: id })
        this.setState({ modalTitle: "Jaarplanning dupliceren" });
        this.setState({ modalButton: "Dupliceer" });
        let modal = document.getElementById("add-edit-year-planning-modal");
        modal.style.display = "block";
        modal.style.opacity = 1;
    }

    closeModals() {
        const body = document.querySelector('body')
        body.style.overflow = "auto"
        let addEditModal = document.getElementById("add-edit-year-planning-modal");
        let removeConfirmModal = document.getElementById("remove-confirm-modal");
        addEditModal.style.display = "none";
        addEditModal.style.opacity = 0;
        removeConfirmModal.style.display = "none";
        removeConfirmModal.style.opacity = 0;
    }

    handleRemoveYearPlanningAccepted() {
        let yearPlanningIdToRemove = this.state.removeId

        axios.post(
            "/jaar_planning/remove_jaar_planning",
            {
                id: yearPlanningIdToRemove,
            },
            { withCredentials: true }
        ).then((response) => {
            console.log("removed " + response.data.removedRows + " task(s)");
            this.getAllYearPlanningsFormDatabase();
            this.closeModals();
        }).catch((error) => {
            console.log("task fetching error", error);
        })
    }

    handleSubmitAddYearPlanning(e) {

        e.preventDefault();

        const formattedFormData = {
            instantie_id: this.state.loggedInUser.instantie_id,
            type_id: parseInt(this.state.addFormType),
            jaar: parseInt(this.state.addFormYear),
            orgaan_id: parseInt(this.state.addFormOrgaan)
        }

        if (this.state.addFormId) {

            const jaarplanningData = {
                id: this.state.addFormId,
                values: { type_id: parseInt(this.state.addFormType),
                            orgaan_id: parseInt(this.state.addFormOrgaan),
                            jaar: parseInt(this.state.addFormYear)},

            }

            axios.post("/jaar_planning/update_jaar_planning",
                { update_jaar_planning: jaarplanningData },
                { withCredentials: true }
            ).then(() => {
                this.resetFormFields();
                this.getAllYearPlanningsFormDatabase();
                this.closeModals();
            }).catch((error) => {
                if (error.response.data.message === "Duplicate jaarplanning, the jaarplanning already exists.") {
                    this.setError("Er bestaat al een jaarplanning met hetzelfde orgaan en jaartal. Jaarplanning is niet gewijzigd!")
                }
        });

        } else if (this.state.duplicateId) {
            axios.post(
                "/jaar_planning/duplicate_jaarplanning",
                {
                    jaarplanning_id: this.state.duplicateId,
                    jaar_planning: formattedFormData,
                },
                { withCredentials: true }
            ).then(() => {
                    this.resetFormFields();
                    this.getAllYearPlanningsFormDatabase();
                    this.closeModals();
                }).catch((error) => {
                    if (error.response.data.message === "Duplicate jaarplanning, the jaarplanning already exists.") {
                        this.setError("Er bestaat al een jaarplanning met hetzelfde orgaan en jaartal. Jaarplanning is niet gedupliceerd!")
                    }
            });
        } else {

            axios.post(
                "/jaar_planning/add_jaar_planning",
                {
                    jaar_planning: formattedFormData,
                },
                { withCredentials: true }
            ).then(() => {
                this.resetFormFields();
                this.getAllYearPlanningsFormDatabase();
                this.closeModals();
                this.setState({ errorMessageToggle: false });
            }).catch((error) => {
                if (error.response.data.message === "Duplicate jaarplanning, the jaarplanning already exists.") {
                    this.setError("Er bestaat al een jaarplanning met hetzelfde orgaan en jaartal. Jaarplanning is niet aangemaakt!")
                }
            });
        }
    }

    setError(errorMessage) {
        this.setState({
            errorMessageToggle: true,
            errorMessage: errorMessage
        });
        const errorMessageToggleFalse = () => {
            this.setState({ errorMessageToggle: false });
        }
        setTimeout(errorMessageToggleFalse, 5000);
    }

    handleOnChangeAddYearPlanningForm(event) {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        })
    }

    resetFormFields() {
        this.setState({
            duplicateId: null,
            addFormId: null,
            addFormType: "1",
            addFormInstantie: "1",
            addFormOrgaan: "",
            addFormYear: ""
        })


    }

    renderTable() {

        const dropdownValuesGenerator = (toSearchFor) => {
            let output = [{ value: null, label: 'Alle' }]
            let flags = []
            let length = this.state.yearPlannings.length, i;

            for( i=0; i<length; i++) {
                if( flags[this.state.yearPlannings[i][toSearchFor]]) continue;
                flags[this.state.yearPlannings[i][toSearchFor]] = true;
                output.push(
                    { value: this.state.yearPlannings[i][toSearchFor], label: this.state.yearPlannings[i][toSearchFor] }
                    );
            }

            return output
        }

        const onChangeFunction = (filterValue, filterName) => {

            let stateName = filterName + 'DropdownValue';
            stateName === 'jaarDropdownValue' ? stateName = 'datumDropdownValue' : null
            sessionStorage.setItem(stateName, filterValue);
                this.reloadFilters()
        }
     
        const orgaanFilterValue = sessionStorage.getItem('orgaanDropdownValue') === 'null' ? null : sessionStorage.getItem('orgaanDropdownValue')
        const jaarFilterValue = sessionStorage.getItem('datumDropdownValue') === 'null' ? null : sessionStorage.getItem('datumDropdownValue')

        if (this.state.yearPlannings.length !== 0) {
            return (
                <table className="table overview" >
                    <thead>
                        <tr>
                            <th scope="col">Type {this.state.test}</th>
                            <th scope="col">
                                <div className="table-dropdown" >
                                Orgaan
                                    <Dropdown
                                        options={dropdownValuesGenerator('orgaan')}
                                        placeholder="Alle"
                                        onChange={event => onChangeFunction(event.value, 'orgaan')}
                                        value={orgaanFilterValue}
                                    />
                                <ReactDropdownArrow />
                                </div>
                            </th>
                            <th scope="col">Instantie</th>
                            <th scope="col">
                                <div className="table-dropdown" >
                                Jaar
                                    <Dropdown
                                        options={dropdownValuesGenerator('jaar')}
                                        placeholder="Alle"
                                        onChange={event => onChangeFunction(event.value, 'jaar')}
                                        value={jaarFilterValue}
                                    />
                                <ReactDropdownArrow />
                                </div>
                            </th>
                            <th scope="col" className="duplicate-label">Dupliceer</th>
                            <th scope="col" className="text-center">Wijzig</th>
                            <th scope="col" className="text-right">Verwijder</th>
                            {/* <p className="hide-shadow"></p> */}
                        </tr>
                    </thead>
                    { this.tableRows()}
                </table>
            )
        } else {
            return (
                <h4 className="ml-4 py-5">Er zijn nog geen jaarplanningen. Klik hier onder en maak er één aan.</h4>
            )
        }
    }

    render() {
        return (
            <>
                <Header titlePage="Overzicht jaarplannningen" />
                <div className="year-plannings"> 
                    {this.renderTable()}

                    { 
                        this.state.loggedInUser.bewerkRechten ? 
                            <div onClick={event => this.openAddEditModal(undefined, event)} className="new-year-planning" >
                                <ReactAddIcon />Nieuwe jaarplanning aanmaken
                            </div> :
                            <div className="new-year-planning">Gebruiker heeft geen rechten om een nieuwe jaarplanning aan te maken</div>
                    }

                    <div id="add-edit-year-planning-modal" className="modal">
                        <div className="modal-content">
                            <div className="modal-head">
                                {this.state.modalTitle}
                            </div>
                            <ReactCloseIcon onClick={this.closeModals} className="close-icon" />
                            <form onSubmit={this.handleSubmitAddYearPlanning} style={{padding: "40px 100px"}} className="add-form-popup">
                                <input type="hidden" name="addFormId" value={this.state.addFormId ? this.state.addFormId : ""} />
                                <div className="row">
                                    <div className="col-md-6">
                                        <p>Type</p>
                                        <div className="center-noMargin">
                                            {this.formYearPlanningTypesSelectField()}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <p>Orgaan</p>
                                        <div className="center-witMargin">
                                            {this.formYearPlanningOrganenSelectField()}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <p>Instantie</p>
                                        <div className="center-noMargin">
                                            <select required value=""
                                                onChange={this.handleOnChangeAddYearPlanningForm}
                                                name="addFormInstantie" id="exampleFormControlSelect1" disabled>
                                                <option value="" >{this.state.loggedInUser.instantie_naam}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <p>Jaar</p>
                                        <div className="center-witMargin">
                                            <select required value={this.state.addFormYear}
                                                onChange={this.handleOnChangeAddYearPlanningForm}
                                                name="addFormYear" id="exampleFormControlSelect1" style={{ backgroundImage: `url(${formArrow})` }}>
                                                <option value="" >Jaar</option>
                                                { this.jaren.map(j => {
                                                     return <option value={j.toString()}>{j}</option>
                                                  })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <input className="button" role="button" type="submit" value={this.state.modalButton} />
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className={this.state.errorMessageToggle ? "error-message" : "error-message d-none"}>{this.state.errorMessage}</div>

                    <div id="remove-confirm-modal" className="modal">
                        <div className="modal-content">
                            <div className="modal-head">
                                Weet u zeker dat u deze jaarplanning wilt verwijderen?
                        </div>
                            <ReactCloseIcon onClick={this.closeModals} className="close-icon" />
                            <form action="">
                            <div  className="user-for-deletion">{this.state.removeJaarplanningOrgaan} {this.state.removeJaarplanningJaar}</div>
                                <div className="row">
                                    <div className="col-sm-6 m-auto d-flex">
                                        <input onClick={this.handleRemoveYearPlanningAccepted} className="button"
                                            role="button" type="button" value="Ja" />
                                    </div>
                                    <div className="col-sm-6 m-auto d-flex">
                                        <input onClick={this.closeModals} className="button gray" role="button"
                                            type="button" value="Nee" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Home;