// login functionality where the given password is checked with the database
import React, { Component } from "react";
import axios from "axios";
import sha256 from "js-sha256";
import floxxLogo from './recources/images/FLOXX_Meetings_zonder_achtergrond.png';
import {ReactComponent as ReactGebruikerIcon} from "./recources/icons/Gebruikersnaam-Icoon.svg";
import {ReactComponent as ReactWachtwoordIcon} from "./recources/icons/Wachtwoord-Icoon.svg";
import {ReactComponent as ReactErrorIcon} from "./recources/icons/error-icon.svg";
import { ReactComponent as ReactCloseIcon } from "./recources/icons/close-icon.svg"

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      loginErrors: "",
      incorrectLoginDetails: false,
      gebruikers: [],
      selectedGebruiker: {},
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChangeInstantie = this.onChangeInstantie.bind(this);
    this.handleSelecteerGebruiker = this.handleSelecteerGebruiker.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleSubmit(event) {
    //TODO make encrypted for password
    let { email, password } = this.state;
    password = sha256(password);
    axios
      .post(
        "/auth",
        {
          user: {
            email: email,
            password: password,
          },
        },
        { withCredentials: true }
      )
      .then((response) => {
        if (response.data.logged_in) {
          this.setState({incorrectLoginDetails: false});
          if (response.data.data.length > 1) {
            this.setState({ gebruikers: response.data.data });
            this.openSelecteerGebruikerDialog();
            return;
          }
          this.props.handleSuccessfulAuth(response.data);
          let userDataToStore = {
            naam: response.data.data[0].naam, instantie_id: response.data.data[0].instantie_id, 
            instantie_naam: response.data.data[0].instantie_naam,
            rollen: response.data.data[0].rollen.split(','), rechten: response.data.data[0].recht_ids, 
            rol_ids: response.data.data[0].rol_ids, is_admin: response.data.data[0].is_admin
          };
          localStorage.setItem('914ebf336ee44d9deba3f19de35417ace22dc318b8a1024bcd9fe9b4f083a9ae', JSON.stringify(userDataToStore));
          window.location.reload(false);
        } else {
          this.setState({incorrectLoginDetails: true})
        }
      })
      .catch((error) => {
        console.log("login error", error);
      });
    event.preventDefault();
  }

  wrongPasswordAlert() {
    if (this.state.incorrectLoginDetails) {
      return (
          <div className="form-error">
            <ReactErrorIcon />E-mail en/of wachtwoord is ongeldig
          </div>
      )
    }
  }

  openSelecteerGebruikerDialog() {
      let modal = document.getElementById("selecteer-gebruikers-modal");
      modal.style.display = "block";
      modal.style.opacity = 1;
  }

  sluitSelecteerGebruikerDialog() {
    let modal = document.getElementById("selecteer-gebruikers-modal");
    modal.style.display = "none";
    modal.style.opacity = 0;
}

handleSelecteerGebruiker() {
  this.sluitSelecteerGebruikerDialog();
  // Een leeg object werkt ook (zie hieronder)
  this.props.handleSuccessfulAuth({});
  let userDataToStore = {
    naam: this.state.selectedGebruiker.naam, instantie_id: this.state.selectedGebruiker.instantie_id, 
    instantie_naam: this.state.selectedGebruiker.instantie_naam,
    rollen: this.state.selectedGebruiker.rollen.split(','), rechten: this.state.selectedGebruiker.recht_ids, 
    rol_ids: this.state.selectedGebruiker.rol_ids, is_admin: this.state.selectedGebruiker.is_admin
  };
  localStorage.setItem('914ebf336ee44d9deba3f19de35417ace22dc318b8a1024bcd9fe9b4f083a9ae', JSON.stringify(userDataToStore));
  window.location.reload(false);
}

onChangeInstantie(event) {
  const instantie_naam = event.target.value;
  const selectedGebruiker = this.state.gebruikers.find(g => g.instantie_naam === instantie_naam);
  this.setState({ selectedGebruiker: selectedGebruiker ? selectedGebruiker : {} });
}

  render() {
    return (
      <div>
        <div className="left-side-FLOXX-image">
            <img alt="Floxx meetings logo" src={floxxLogo} />
        </div>

        <div className="right-side-logInForm-container">
          <div className='logInTitle'>
            Inloggen FLOXX Meetings                               
          </div>

          <div className='logInText'>
            Het systeem FLOXX Meetings ondersteunt uw organisatie bij het maken van jaarplanningen. Voor alle organen, 
            zoals bestuur en commissies, kunt u een jaarplanning maken. In de jaarplanning kunt u per onderwerp aangeven 
            in welke maand u het onderwerp wilt behandelen en wie verantwoordelijk is. U kunt vanuit de jaarplanning een 
            vergaderagenda genereren voor de eerstkomende vergadering. Alle punten uit de jaarplanning worden automatisch 
            ingevuld en extra punten kunnen worden toegevoegd. De jaarplanning voor volgend jaar maken? Eenvoudig een 
            kopie maken van het huidige jaar en naar wens aanpassen.
          </div>

          <form onSubmit={this.handleSubmit}>
            <div className="form-input-container">
              <i className="form-icon"> 
                <ReactGebruikerIcon/> 
              </i>
              <input
                className="form-input-field" 
                type="email"
                name="email"
                placeholder="Gebruikersnaam"
                value={this.state.email}
                onChange={this.handleChange}
                required
                />
            </div>
            <div className="form-input-container">
              <i className="form-icon"> 
                <ReactWachtwoordIcon/> 
              </i>
              <input
                className="form-input-field" 
                type="password"
                name="password"
                placeholder="Wachtwoord"
                value={this.state.password}
                onChange={this.handleChange}
                required
                />
            </div>
            {/*<h1>Wachtwoord vergeten?</h1>*/}
            { this.wrongPasswordAlert() }
            <div>
              <button type="submit" className='Login-button' > Inloggen </button>
            </div>                 
          </form>

          <h2><span>Nieuw bij FLOXX Meetings?</span></h2>
          <h3><span>Klik <a target="_blank" href="https://www.floxx.nu/floxx-meetings/">hier</a> om een offerte aan te vragen voor een gratis proef maand.</span></h3>
        </div>
        <div>
            {/* Selecteer instantie dialog */}
            <div id="selecteer-gebruikers-modal" className="modal">
              <div className="modal-content">
                  <div className="modal-head">
                      Selecteer instantie
                      <ReactCloseIcon onClick={this.sluitSelecteerGebruikerDialog} className="close-icon" />
                  </div>
                  <form onSubmit={this.handleSelecteerGebruiker} className="add-form-popup">
                    <div className="row center">
                      U kunt inloggen bij {this.state.gebruikers.length} instanties:
                      <select onChange={this.onChangeInstantie} required>
                        <option value="">Selecteer een instantie</option>
                        {this.state.gebruikers.map(g => {
                          return <option key={g.instantie_id}>{g.instantie_naam}</option>
                        })}
                      </select>

                        
                      </div>
                      <div className="row">
                      <input className="button" role="button" type="submit" value="OK" />
                      </div>
                  </form>
                  <div className="row">
                  </div>
              </div>
          </div>
        </div>
      </div>
    );
  }
}